import { useMemo } from 'react';

import { IAccount } from '@entities/accounts/model';
import { IOperation } from '@entities/transactions/model';
import { ECurrency } from '@lib/currency';
import { client } from '@shared/api';
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

export enum ETicketType {
  CREATE_ACCOUNT = 'CREATE_ACCOUNT',
  CREATE_CARD = 'CREATE_CARD',
  WITHDRAW_CASH = 'WITHDRAW_CASH',
  DEPOSIT_CASH = 'DEPOSIT_CASH',
}

export enum ETicketStatus {
  CREATED = 'CREATED',
  CLOSED_BY_CLIENT = 'CLOSED_BY_CLIENT',
  CLOSED_BY_ADMIN = 'CLOSED_BY_ADMIN',
  PROCESSED = 'PROCESSED',
}

// Interfaces
export interface ITicket {
  id: string;
  type: ETicketType;
  status: ETicketStatus;
  createdAt: string;

  // DEPOSIT_CASH specific properties
  depositCashCashAmount?: number;
  depositCashCashCurrency?: ECurrency;
  depositCashCreditAmount?: number;
  depositCashDate?: string;
  depositCashCreditAccount?: IAccount;
  depositCashOperation?: IOperation;

  // WITHDRAW_CASH specific properties
  withdrawCashDebitAmount?: number;
  withdrawCashCashCurrency?: ECurrency;
  withdrawCashCashAmount?: number;
  withdrawCashDate?: string;
  withdrawCashDebitAccount?: IAccount;
  withdrawCashOperation?: IOperation;

  // CREATE_ACCOUNT specific properties
  createAccountCurrency?: ECurrency;
  createAccountName?: string;
  createAccountAccount?: IAccount;

  // CREATE_CARD specific properties
  createCardHolder?: string;
  createCardName?: string;
  createCardCard?: {
    id: string;
    number: string;
  };

  // Common properties for all ticket types
  office?: {
    id: string;
    country: string;
    city: string;
    street: string | null;
    email: string;
    phone: string;
    dateLag: number;
    timeZone: string;
  };
}

export interface IPaginatedTickets {
  results: ITicket[];
  total: number;
}

export interface IGetTicketsParams {
  take?: number;
  skip?: number;
  orderBy?: 'asc' | 'desc';
}

export interface IWithdrawCashPayload {
  accountId: string;
  cashCurrency: ECurrency;
  debitAmount: number;
  sig: string;
  when: string;
  officeId: string;
}

export interface IDepositCashPayload {
  accountId: string;
  officeId: string;
  cashAmount: number;
  cashCurrency: ECurrency;
  sig: string;
  when: string;
}

export interface ICreateCardPayload {
  holder: string;
  name: string;
  deliveryAddress: string;
  comment: string;
}

export interface ICreateAccountPayload {
  currency: ECurrency;
  name: string;
}

export interface IDepositCashDetailsPayload {
  accountId: string;
  cashCurrency: ECurrency;
}

export interface IDepositCashDetailsResponse {
  sig: string;
}

export interface IWithdrawCashDetailsPayload {
  accountId: string;
  cashCurrency: ECurrency;
}

export interface IWithdrawCashDetailsResponse {
  sig: string;
}

export const useGetTicket = (
  ticketId: string,
  options?: Omit<UseQueryOptions<ITicket, AxiosError>, 'queryKey' | 'queryFn'>,
) => {
  const query = useQuery<ITicket, AxiosError>({
    queryKey: ['ticket', ticketId],
    queryFn: () =>
      client.get(`/client/tickets/${ticketId}`).then((res) => res.data),
    ...options,
  });

  return {
    ticket: query.data,
    ...query,
  };
};

export const useGetTickets = (
  params: IGetTicketsParams = {},
  options?: Omit<
    UseQueryOptions<IPaginatedTickets, AxiosError>,
    'queryKey' | 'queryFn'
  >,
) => {
  const query = useQuery<IPaginatedTickets, AxiosError>({
    queryKey: ['tickets', params],
    queryFn: () =>
      client.get('/client/tickets', { params }).then((res) => res.data),
    ...options,
  });

  const tickets = useMemo(() => query.data?.results || [], [query.data]);
  const total = useMemo(() => query.data?.total || 0, [query.data]);

  return {
    tickets,
    total,
    ...query,
  };
};

export const useCreateWithdrawCashTicket = () => {
  const mutation = useMutation<ITicket, AxiosError, IWithdrawCashPayload>({
    mutationFn: (payload) =>
      client
        .post('/client/tickets/withdraw-cash', payload)
        .then((res) => res.data),
  });

  return {
    createWithdrawCashTicket: mutation.mutateAsync,
    ...mutation,
  };
};

export const useWithdrawCashDetails = (
  options?: Omit<
    UseMutationOptions<
      IWithdrawCashDetailsResponse,
      AxiosError,
      IWithdrawCashDetailsPayload
    >,
    'mutationFn'
  >,
) => {
  return useMutation<
    IWithdrawCashDetailsResponse,
    AxiosError,
    IWithdrawCashDetailsPayload
  >({
    mutationFn: (payload) =>
      client
        .put('/client/tickets/withdraw-cash', payload)
        .then((res) => res.data),
    ...options,
  });
};

export const useCreateDepositCashTicket = () => {
  const mutation = useMutation<ITicket, AxiosError, IDepositCashPayload>({
    mutationFn: (payload) =>
      client
        .post('/client/tickets/deposit-cash', payload)
        .then((res) => res.data),
  });

  return {
    createDepositCashTicket: mutation.mutateAsync,
    ...mutation,
  };
};

export const useDepositCashDetails = (
  options?: Omit<
    UseMutationOptions<
      IDepositCashDetailsResponse,
      AxiosError,
      IDepositCashDetailsPayload
    >,
    'mutationFn'
  >,
) => {
  return useMutation<
    IDepositCashDetailsResponse,
    AxiosError,
    IDepositCashDetailsPayload
  >({
    mutationFn: (payload) =>
      client
        .put('/client/tickets/deposit-cash', payload)
        .then((res) => res.data),
    ...options,
  });
};

export const useCreateCardTicket = () => {
  const mutation = useMutation<ITicket, AxiosError, ICreateCardPayload>({
    mutationFn: (payload) =>
      client
        .post('/client/tickets/create-card', payload)
        .then((res) => res.data),
  });

  return {
    createCardTicket: mutation.mutateAsync,
    ...mutation,
  };
};

export const useCreateAccountTicket = () => {
  const mutation = useMutation<ITicket, AxiosError, ICreateAccountPayload>({
    mutationFn: (payload) =>
      client
        .post('/client/tickets/create-account', payload)
        .then((res) => res.data),
  });

  return {
    createAccountTicket: mutation.mutateAsync,
    ...mutation,
  };
};
