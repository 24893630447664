import { forwardRef } from 'react';

import { cn } from '@lib/utils';
import { Input, InputProps } from '@ui/input';

type FormInputProps = {
  icon?: JSX.Element;
  error?: string;
} & InputProps &
  React.RefAttributes<HTMLInputElement> & { rootClassName?: string };

const ERROR_SHADOW =
  'shadow-[0px_0px_0px_1px_rgba(223,_28,_65,_0.20)_inset,_0px_0px_1px_3px_rgba(223,_28,_65,_0.15)]';

export const FormInput = forwardRef(function (
  { icon, error, rootClassName, disabled, ...props }: FormInputProps,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  return (
    <div
      className={cn(
        'relative w-full',
        { 'opacity-50': disabled },
        rootClassName,
      )}
    >
      <Input
        inputSize="lg"
        {...props}
        ref={ref}
        className={cn(
          { 'pl-[3.25rem]': !!icon },
          { [ERROR_SHADOW]: !!error },
          'opacity-100',
          props.className,
        )}
        disabled={disabled}
      />
      {!!icon && (
        <div className="absolute left-4 top-1/2 -translate-y-1/2">{icon}</div>
      )}
    </div>
  );
});
