import { CurrencyIcon } from '@components/currency-icon';
import { cn } from '@lib/utils';
import { ClassValue } from 'clsx';
import { TSelectedCurrency } from '../select/types';

type CurrencyButtonProps = {
  currencyName?: string | null;
  currency?: TSelectedCurrency;
  className?: ClassValue;
  icon?: JSX.Element | null;
};

export const CurrencyButton = ({
  currency,
  currencyName,
  className,
  icon,
}: CurrencyButtonProps) => {
  const renderCurrencyName = () => {
    if (!currency) return 'Select currency';

    if (currencyName) {
      return `${currencyName} (${currency})`;
    }

    return currency;
  };

  return (
    <div className={cn('flex items-center gap-3', className)}>
      {!!currency && (
        <div>
          <CurrencyIcon currency={currency} className="bg- size-5" />
        </div>
      )}
      <div className="text-sm font-medium">
        <span>{renderCurrencyName()}</span>
      </div>
      {!!icon && <div className="ml-auto">{icon}</div>}
    </div>
  );
};
