import { ComponentProps, ReactNode } from 'react';

import { ECurrency } from '@lib/currency';
import { cn } from '@lib/utils';
import { Badge } from '@ui/badge';

import TransactionBadge from './transaction-badge';

interface Props {
  number?: string;
  currency?: ReactNode | ECurrency;
  card?: string;
}

const TransactionCard = ({
  number,
  card,
  currency,
  className,
  ...props
}: Props & ComponentProps<typeof Badge>) => {
  return (
    <TransactionBadge
      className={cn(
        'flex h-[36px] w-[60px] justify-between rounded-md border-shadow-gray bg-transparent px-[6px] leading-[80%] text-neutral01 shadow-shadow-base',
        className,
      )}
      {...props}
    >
      {card && <img className="w-3" src={card} alt="card" />}
      {currency && <span className="ml-auto">{currency}</span>}
      {number && <span>{number}</span>}
    </TransactionBadge>
  );
};

export default TransactionCard;
