import { IAccount } from '@entities/accounts/model';
import { ICard } from '@entities/cards';
import { IMessage } from '@entities/messages/model';
import { IOperation } from '@entities/transactions';
import { client } from '@shared/api';
import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

export enum ENotificationType {
  ACCOUNT_CREATED = 'ACCOUNT_CREATED',
  CARD_CREATED = 'CARD_CREATED',
  CARD_OPERATION = 'CARD_OPERATION',
  CARD_TRANSACTION = 'CARD_TRANSACTION',
  REFUND_PROCESSED = 'REFUND_PROCESSED',
  FAILED_LOGIN = 'FAILED_LOGIN',
  LOGIN = 'LOGIN',
  PASSWORD_RESET = 'PASSWORD_RESET',
  PASSWORD_UPDATED = 'PASSWORD_UPDATED',
  TICKET_STATUS_CHANGED = 'TICKET_STATUS_CHANGED',
  MESSAGE_CREATED = 'MESSAGE_CREATED',
}

export enum ENotificationDestination {
  WHATSAPP = 'WHATSAPP',
  TELEGRAM = 'TELEGRAM',
  SMS = 'SMS',
}

export interface INotification {
  id: string;
  type: ENotificationType;
  createdAt: string;
  isRead: boolean;
  message: string;
  accountCreatedAccount: IAccount;
  cardCreatedCard: ICard;
  ticketStatusChangedTicket: ITicketStatusChangedTicket;
  ticketStatusChangedStatus: TicketStatus;
  refundProcessedOperation: IOperation;
  cardOperation: IOperation;
  messageCreatedMessage: IMessage;
  failedLoginIp?: string;
  loginSession?: string;
  passwordReset?: boolean;
  passwordUpdated?: boolean;
  loginIp: string;
}

export interface ITicketStatusChangedTicket {
  id: string;
  adminId: string;
  type: TicketType;
  status: string;
  depositCashCashAmount: number;
  depositCashCashCurrency: string;
  depositCashCreditAmount: number;
  depositCashDate: string;
  withdrawCashDebitAmount: number;
  withdrawCashCashCurrency: string;
  withdrawCashCashAmount: number;
  withdrawCashDate: string;
  createAccountCurrency: string;
  createAccountName: string;
  createCardHolder: string;
  createCardName: string;
  createdAt: string;
}

export enum TicketType {
  CREATE_ACCOUNT = 'CREATE_ACCOUNT',
  CREATE_CARD = 'CREATE_CARD',
  WITHDRAW_CASH = 'WITHDRAW_CASH',
  DEPOSIT_CASH = 'DEPOSIT_CASH',
}

export enum TicketStatus {
  CREATED = 'CREATED',
  CLOSED_BY_CLIENT = 'CLOSED_BY_CLIENT',
  CLOSED_BY_ADMIN = 'CLOSED_BY_ADMIN',
  PROCESSED = 'PROCESSED',
}

export interface IPaginatedNotifications {
  results: INotification[];
  total: number;
  unread: number;
}

export interface INotificationTypeToDestinations {
  type: ENotificationType;
  destinations: ENotificationDestination[];
}

export interface INotificationsSettings {
  typeToDestinations: INotificationTypeToDestinations[];
}

export interface IGetNotificationsParams {
  sort?: 'asc' | 'desc';
  skip?: number;
  take?: number;
  types?: ENotificationType[];
}

// Hooks

export const useUpdateNotificationSettings = () => {
  return useMutation<
    INotificationsSettings,
    AxiosError,
    INotificationsSettings
  >({
    mutationFn: (payload) =>
      client
        .patch('/client/notifications/settings', payload)
        .then((res) => res.data),
  });
};

export const useGetNotificationSettings = (
  options?: Omit<
    UseQueryOptions<INotificationsSettings, AxiosError>,
    'queryKey' | 'queryFn'
  >,
) => {
  return useQuery<INotificationsSettings, AxiosError>({
    queryKey: ['notificationSettings'],
    queryFn: () =>
      client.get('/client/notifications/settings').then((res) => res.data),
    ...options,
  });
};

export const useGetNotifications = (
  params: IGetNotificationsParams = {},
  options?: Omit<
    UseQueryOptions<IPaginatedNotifications, AxiosError>,
    'queryKey' | 'queryFn'
  >,
) => {
  return useQuery<IPaginatedNotifications, AxiosError>({
    queryKey: ['notifications', params],
    queryFn: () =>
      client.get('/client/notifications', { params }).then((res) => res.data),
    ...options,
  });
};
