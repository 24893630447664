import { useState } from 'react';
import { Select } from '@components/form';
import { FullscreenModal, FullscreenModalContainer } from '@components/modal';
import type {
  TOnCurrencyChange,
  TRenderTrigger,
  TSelectedCurrency,
} from '@components/form/select';
import { CurrencyItem } from './currency-item';
import { CurrencyDropdownButton } from '../../currency-dropdown-button';
import { useGetCurrencies } from '@entities/finance/model';

export interface ICurrencySelectProps {
  currency?: TSelectedCurrency;
  onCurrencyChange?: TOnCurrencyChange;
  title?: string;
  isCentered?: boolean;
  modalTitle?: string;
  renderTrigger?: TRenderTrigger<TSelectedCurrency>;
}

export const CurrencySelect = ({
  currency,
  title,
  onCurrencyChange,
  isCentered,
  modalTitle,
  renderTrigger,
}: ICurrencySelectProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const { data, isLoading } = useGetCurrencies();
  const currencies = data?.currencies;

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  return (
    <Select.ConfirmableSelect.Provider
      value={currency}
      onChange={onCurrencyChange}
      onListOpen={open}
      onListClose={close}
      isOpen={isOpen}
    >
      {renderTrigger ? (
        renderTrigger({ active: currency, onListOpen: open })
      ) : (
        <CurrencyDropdownButton name={currency} currency={currency} />
      )}

      <FullscreenModal open={isOpen} onOpenChange={setIsOpen}>
        <FullscreenModalContainer title={modalTitle} isCentered={isCentered}>
          <Select.ConfirmableSelect.List
            title={title}
            isLoading={isLoading}
            isSearchable={false}
          >
            {currencies?.map((currency) => (
              <CurrencyItem currency={currency} key={currency} />
            ))}
          </Select.ConfirmableSelect.List>
        </FullscreenModalContainer>
      </FullscreenModal>
    </Select.ConfirmableSelect.Provider>
  );
};
