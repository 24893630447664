import { cn } from '@lib/utils';
import { Check } from 'lucide-react';
import { useContext } from 'react';
import { SelectContext } from './provider';
import { TDefaultValue } from '../types';

export interface IItem<TValue extends TDefaultValue>
  extends React.HTMLAttributes<HTMLButtonElement> {
  value: TValue;
}

export const Item = <TValue extends TDefaultValue>({
  value,
  children,
  ...props
}: IItem<TValue>) => {
  const { localeValue, onChange } = useContext(SelectContext);

  const isActive = value === localeValue;

  return (
    <button
      data-item={value}
      className={cn('group flex w-full items-center', {
        'text-neutral01': isActive,
      })}
      onClick={onChange}
      {...props}
    >
      <div className="flex flex-1 items-center gap-3 px-4 py-[1.125rem] text-left">
        {children}
      </div>
      <div className="flex size-[3.75rem] items-center justify-center">
        {isActive && <Check size={20} />}
      </div>
    </button>
  );
};
