import { Select } from '@components/form';
import { CurrencyItemContent } from './currency-item-content';

export interface ICurrencyItemProps {
  currency?: Select.TSelectedCurrency;
}

export const CurrencyItem = ({ currency }: ICurrencyItemProps) => (
  <Select.ConfirmableSelect.Item key={currency} value={currency}>
    <CurrencyItemContent currency={currency} />
  </Select.ConfirmableSelect.Item>
);
