import { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import MastercardIcon from '@assets/icons/mastercard.svg';
import { getCurrencySymbol } from '@components/dashboard/balance-section/total-balance';
import { EAccountType } from '@entities/accounts/model';
import {
  EDirection,
  ETransactionStatus,
  ETransactionType,
  IOperation,
  ITransactionGroup,
} from '@entities/transactions/model';
import { cn } from '@lib/utils';
import { routesMap } from '@shared/lib';
import clsx from 'clsx';
import { format as formatHelper } from 'date-fns';

import TransactionBadge from './table/transaction-badge';
import TransactionCard from './table/transaction-card';

export const formatDate = (
  dateString: string,
  format: 'full' | 'short' | 'time' | 'date' = 'full',
) => {
  const date = new Date(dateString);
  const optionsFull: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };
  const optionsShort: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
  };
  const optionsTime: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  const isYearDisplayed = new Date().getFullYear() !== date.getFullYear();

  switch (format) {
    case 'full':
      return date.toLocaleDateString('en-GB', optionsFull);
    case 'short':
      return date.toLocaleDateString('en-GB', optionsShort).toUpperCase();
    case 'time':
      return date.toLocaleTimeString('en-GB', optionsTime).toLowerCase();
    case 'date':
      return formatHelper(date, `MMMM dd${isYearDisplayed ? ' yyyy' : ''}`);
    default:
      return date.toLocaleDateString('en-GB', optionsFull);
  }
};

export const formatUnmaskedCardNumber = (cardNumber: string): string => {
  return cardNumber.replace(/\d{4}(?=.)/g, '$& ');
};

export const getFormattedCardNumber = (
  cardNumber: string,
  noCardPrefix = false,
) => {
  const lastFourDigits = cardNumber?.slice(-4) || '';
  return `${noCardPrefix ? '' : 'CARD'} •••• ${lastFourDigits}`;
};

export const getDescription = (
  operation: ITransactionGroup,
  styles?: string,
  checking = false,
) => {
  if (!operation || !operation.operations) return '';
  const [outOperation, inOperation] = operation.operations;

  if (!outOperation) return '';

  if (outOperation.type === ETransactionType.CARD && outOperation.card) {
    const path = routesMap.cards.full + '/' + outOperation.card.id;

    return (
      <Link to={path}>
        <TransactionCard
          className={cn('mx-auto', styles)}
          card={MastercardIcon}
          number={outOperation.card.number}
        />
      </Link>
    );
  }

  if (outOperation.type === ETransactionType.EXCHANGE) {
    const debitAccountAddress = inOperation.debitAccount.address;

    const currencyToDisplay = debitAccountAddress.includes('system:')
      ? inOperation.creditAccount.currency
      : inOperation.debitAccount.currency;

    const type = inOperation.creditAccount.type;
    let path: string;

    if (type === EAccountType.CHECKING) {
      path = routesMap.accounts.full + '/' + inOperation.creditAccount.id;
    } else {
      path = routesMap.savings.full + '/' + inOperation.creditAccount.id;
    }

    return (
      <Link to={path} className="flex items-center gap-1">
        {checking && <span>checking</span>}
        <TransactionCard
          className={cn('mx-auto', clsx({ 'w-max': checking }), styles)}
          currency={currencyToDisplay}
        />
      </Link>
    );
  }

  const debitAccountAddress = outOperation.debitAccount.address;

  const isDebitSystem = debitAccountAddress.includes('system:');

  const currencyToDisplay = isDebitSystem
    ? outOperation.creditAccount.currency
    : outOperation.debitAccount.currency;

  const type = outOperation.debitAccount.type;
  let path: string;
  const route = isDebitSystem
    ? outOperation.creditAccount.id
    : outOperation.debitAccount.id;

  if (type === EAccountType.CHECKING) {
    path = routesMap.accounts.full + '/' + route;
  } else {
    path = routesMap.savings.full + '/' + route;
  }

  return (
    <Link to={path} className="flex items-center gap-1">
      {checking && <span>checking</span>}
      <TransactionCard
        className={cn('mx-auto', clsx({ 'w-max': checking }), styles)}
        currency={currencyToDisplay}
      />
    </Link>
  );
};

export const getExchangeAccounts = (transactionGroup?: ITransactionGroup) => {
  if (transactionGroup?.operations?.[0]?.type !== ETransactionType.EXCHANGE)
    return;

  const fromOperation = transactionGroup.operations[0];
  const toOperation = transactionGroup.operations[1];

  const from = fromOperation?.debitAccount;
  const to = toOperation?.creditAccount;

  if (!from && !to) return;

  return {
    from,
    to,
    fromOperation,
    toOperation,
  };
};

export const groupByDate = (transactionGroups: ITransactionGroup[]) => {
  return transactionGroups.reduce(
    (acc: { [key: string]: ITransactionGroup[] }, group) => {
      const date = formatDate(group.createdAt, 'date');
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(group);
      return acc;
    },
    {},
  );
};

export const getStatus = (operation: IOperation, styles?: string) => {
  const fromValue = operation?.debitAmount;
  const fromCurrency = operation?.debitAccount?.currency;

  const pendingBadge = (
    <TransactionBadge className={cn('pointer-events-none', styles)}>
      Pending
    </TransactionBadge>
  );

  const rejectedBadge = (
    <TransactionBadge className={cn('pointer-events-none', styles)}>
      Rejected
    </TransactionBadge>
  );

  const completedBadge = (operation.type === ETransactionType.WITHDRAW ||
    operation.type === ETransactionType.DEPOSIT) && (
    <TransactionBadge className={cn('pointer-events-none', styles)}>
      Completed
    </TransactionBadge>
  );

  const displayedStatus = {
    [ETransactionStatus.PENDING]: pendingBadge,
    [ETransactionStatus.REJECTED]: rejectedBadge,
    [ETransactionStatus.COMPLETE]: completedBadge,
  };

  const displayedAmount = operation.type === ETransactionType.EXCHANGE && (
    <TransactionBadge className={cn('pointer-events-none', styles)}>
      from {fromValue} {getCurrencySymbol(fromCurrency)}
    </TransactionBadge>
  );

  if (displayedAmount && displayedStatus[operation.status]) {
    return (
      <>
        {displayedAmount}
        {displayedStatus[operation.status]}
      </>
    );
  }

  if (displayedAmount) {
    return displayedAmount;
  }

  if (displayedStatus[operation.status]) {
    return displayedStatus[operation.status];
  }
};

export const getTitle = (
  transactionGroup: ITransactionGroup,
  showStatus: boolean = true,
) => {
  const operation = transactionGroup?.operations?.[0];
  if (!operation) return 'Unknown Transaction';

  const { merchantName } = operation;

  const formattedType = operation.type.replace('_', ' ');

  const status = getStatus(operation);

  const titleStyles =
    'max-w-[500px] overflow-hidden w-full text-ellipsis whitespace-nowrap';

  if (operation.type === ETransactionType.CARD) {
    return (
      <>
        <p className={titleStyles}>{merchantName || formattedType}</p>
        {showStatus && status}
      </>
    );
  }

  if (operation.type === ETransactionType.EXCHANGE) {
    return (
      <>
        <p className={titleStyles}>{ETransactionType.EXCHANGE} </p>
        {showStatus && status}
      </>
    );
  }

  return (
    <>
      <p className={titleStyles}>{formattedType}</p>
      {showStatus && status}
    </>
  );
};

export const getAmount = (
  transactionGroup: ITransactionGroup,
  as: 'string' | 'node' = 'node',
) => {
  const operation = transactionGroup?.operations?.[0];
  if (!operation) {
    return {
      formattedValue: '0',
      amount: 0,
      currency: '',
    };
  }

  let amount = 0;
  let formattedValue: string | ReactElement = '0';
  let currency = '';

  if (operation.type === ETransactionType.EXCHANGE) {
    const outOperation = transactionGroup.operations[0];
    const inOperation = transactionGroup.operations[1];
    amount = Number(outOperation.debitAmount);
    currency = outOperation.debitAccount.currency;
    const receivedAmount = Number(inOperation?.creditAmount) || 0;
    const receivedCurrency = inOperation?.creditAccount?.currency || '';
    formattedValue = `-${formatBalance(amount)} ${currency} / +${formatBalance(receivedAmount)} ${getCurrencySymbol(receivedCurrency)}`;
  } else {
    const direction = operation?.debitAccount?.id?.startsWith('system:')
      ? EDirection.IN
      : EDirection.OUT;
    if (direction === EDirection.IN) {
      amount = Number(operation.creditAmount);
      currency = operation.creditAccount.currency;
      formattedValue =
        as === 'string' ? (
          `+${formatBalance(amount)} ${getCurrencySymbol(currency)}`
        ) : (
          <>
            +{formatBalance(amount)}{' '}
            <span className="text-neutral04">
              {getCurrencySymbol(currency)}
            </span>
          </>
        );
    } else {
      amount = Number(operation.debitAmount);
      currency = operation.debitAccount.currency;
      formattedValue =
        as === 'string' ? (
          `-${formatBalance(amount)} ${getCurrencySymbol(currency)}`
        ) : (
          <>
            -{formatBalance(amount)}{' '}
            <span className="text-neutral04">
              {getCurrencySymbol(currency)}
            </span>
          </>
        );
    }
  }

  return {
    formattedValue,
    amount,
    currency,
  };
};

export const formatBalance = (balance: number): string => {
  const parts = (+balance).toFixed(2).split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};
