import { FC } from 'react';

import { IconProps } from './types';

const CardIcon: FC<IconProps> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      className={className}
    >
      <g></g>
      <path
        d="M1.83398 7.79639H20.1673"
        stroke="#D1D5DB"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5 15.1294H7.33333"
        stroke="#D1D5DB"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.625 15.1294H13.2917"
        stroke="#D1D5DB"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.90398 3.21289H16.0881C19.3515 3.21289 20.1673 4.01956 20.1673 7.23706V14.7629C20.1673 17.9804 19.3515 18.7871 16.0973 18.7871H5.90398C2.64982 18.7962 1.83398 17.9896 1.83398 14.7721V7.23706C1.83398 4.01956 2.64982 3.21289 5.90398 3.21289Z"
        stroke="#D1D5DB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CardIcon;
