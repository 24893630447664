import { useEffect } from 'react';

export const useScrollBehavior = (bool: boolean, willExecute = true) => {
  useEffect(() => {
    if (willExecute) {
      if (bool) {
        document.documentElement.style.scrollBehavior = 'auto';
      } else {
        setTimeout(() => {
          document.documentElement.style.scrollBehavior = 'smooth';
        }, 1000);
      }
    }
  }, [bool, willExecute]);
};
