import CardIcon from '@/icons/CardIcon';
import HomeIcon from '@/icons/HomeIcon';
import PercentageIcon from '@/icons/PercentageIcon';
import WalletIcon from '@/icons/WalletIcon';
import { routesMap } from '@shared/lib';

export type linkObj = {
  path: string;
  title: string;
  icon: JSX.Element;
};

export const links: linkObj[] = [
  { path: routesMap.home, title: 'Dashboard', icon: <HomeIcon /> },
  { path: routesMap.cards.full, title: 'Cards', icon: <CardIcon /> },
  { path: routesMap.accounts.full, title: 'Accounts', icon: <WalletIcon /> },
  { path: routesMap.savings.full, title: 'Savings', icon: <PercentageIcon /> },
];
