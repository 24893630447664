import { cn } from '@lib/utils';
import { Label } from '@ui/label';

export type FormLabelProps = {
  className?: string;
  label?: string;
  slotProps?: {
    labelParagraph?: React.HTMLAttributes<HTMLParagraphElement>;
  };
} & React.PropsWithChildren;

export const FormLabel = ({
  label,
  children,
  className,
  slotProps,
}: FormLabelProps) => {
  return (
    <Label className={cn('w-full text-base', className)}>
      {label && (
        <p className="mb-[0.375rem]" {...slotProps?.labelParagraph}>
          {label}
        </p>
      )}
      {children}
    </Label>
  );
};
