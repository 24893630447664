import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { useGetClientProfile } from '@entities/client/model';
import { useCreateCardTicket } from '@entities/tickets/model';
import { zodResolver } from '@hookform/resolvers/zod';
import { toastAxiosError } from '@shared/api';
import { displaySuccessForOfflineAction } from '@shared/lib';
import { z } from 'zod';

const requestCardSchema = z.object({
  name: z.string().min(1, 'Name is required'),
  country: z.string().min(1, 'Country is required'),
  deliveryAddress: z.string().min(1, 'Delivery address is required'),
  preferences: z.string().optional(),
});

type TRequestCardFormValues = z.infer<typeof requestCardSchema>;

export interface IUseRequestCardForm {
  onSuccess?: () => void;
}

export const useRequestCardForm = ({ onSuccess }: IUseRequestCardForm = {}) => {
  const { createCardTicket, isPending } = useCreateCardTicket();
  const { refetch, isLoading } = useGetClientProfile({ enabled: false });

  const form = useForm<TRequestCardFormValues>({
    resolver: zodResolver(requestCardSchema),
    defaultValues: {
      name: '',
      country: '',
      deliveryAddress: '',
      preferences: '',
    },
  });

  const mountProfileData = async () => {
    try {
      const profile = await refetch().then((res) => res?.data);

      if (!profile) return;
      if (profile.firstName && profile.lastName && !form.getValues('name')) {
        const fullName = `${profile.firstName} ${profile.lastName}`.trim();
        form.setValue('name', fullName);
      }

      // if (profile.address) {
      //   form.setValue('deliveryAddress', profile.address);
      // }
    } catch (err) {
      if (!navigator.onLine) {
        displaySuccessForOfflineAction();
      }
      console.error('[Mount profile data]', err);
    }
  };

  useEffect(() => {
    mountProfileData();
  }, []);

  const onSubmit = async (data: TRequestCardFormValues) => {
    try {
      await createCardTicket({
        holder: data.name,
        name: data.name,
        deliveryAddress: `${data.country}, ${data.deliveryAddress}`,
        comment: data.preferences || '',
      });

      onSuccess && onSuccess();
    } catch (error) {
      if (!navigator.onLine) {
        return displaySuccessForOfflineAction();
      } else {
        console.error('Failed to create card ticket:', error);
        toastAxiosError({ error });
      }
    }

    form.reset();
  };

  return { form, onSubmit, isPending, isLoading };
};
