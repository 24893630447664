import { useState } from 'react';

import { currencyCodes } from '@entities/currency';
import { ECurrency } from '@lib/currency';
import { cn } from '@lib/utils';
import { ClassValue } from 'clsx';

export type CurrencyIconFigure = 'circle' | 'rounded-square';
export type CurrencyIconProps = {
  currency?: keyof typeof ECurrency | undefined | null;
  emptyIcon?: JSX.Element;
} & React.ImgHTMLAttributes<HTMLImageElement>;

const getFigureClassName = (figure: CurrencyIconFigure): ClassValue => {
  switch (figure) {
    case 'circle':
      return 'rounded-full';
    case 'rounded-square':
      return 'rounded-sm';
    default:
      return '';
  }
};

export const CurrencyIcon = ({
  currency,
  className,
  emptyIcon,
  ...props
}: CurrencyIconProps) => {
  const [isError, setIsError] = useState(false);

  //@ts-ignore
  const countryCode = currencyCodes[currency?.toUpperCase()];
  const flagUrl = `https://flagcdn.com/w80/${countryCode}.webp`;

  if (isError || !currency || !countryCode) {
    return emptyIcon || null;
  }

  return (
    <img
      src={flagUrl}
      alt={currency}
      width="25"
      height="25"
      loading="lazy"
      className={cn(getFigureClassName('circle'), 'object-cover', className)}
      onError={setIsError.bind(null, true)}
      {...props}
    />
  );
};
