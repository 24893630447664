import { useEffect, useRef } from 'react';

export const useScrollPositionLocking = (bool: boolean, willExecute = true) => {
  const scrollPosition = useRef(0);

  const lockScrollPosition = () => {
    scrollPosition.current =
      window.scrollY || document.documentElement.scrollTop;

    document.body.style.position = 'fixed';
    document.body.style.top = `-${scrollPosition.current}px`;
    document.body.style.left = '0';
    document.body.style.width = '100%';
    document.body.style.overflow = 'hidden';
  };

  const unlockScrollPosition = () => {
    document.body.style.position = '';
    document.body.style.top = '';
    document.body.style.left = '';
    document.body.style.width = '';
    document.body.style.overflow = '';

    window.scrollTo(0, scrollPosition.current);
  };

  useEffect(() => {
    if (willExecute) {
      if (bool) {
        lockScrollPosition();
      } else {
        unlockScrollPosition();
      }
    }

    return () => {
      unlockScrollPosition();
    };
  }, [bool, willExecute]);
};
