import { FC } from 'react';

import FooterSection from '@components/footer';

import Navbar from './navbar';
import Support from './support';

// TODO: refactor classes
// TODO: color pallete
// TODO: gradients tailwind var's
// TODO: refactor Chip component (DRY)
// TODO: Framer Motion anime bug with scroll
// TODO: FM on mobile ????

export interface IFooterProps {
  isNavbar?: boolean;
  className?: string;
}

const Footer: FC<IFooterProps> = ({ isNavbar = true, className }) => {
  return (
    <>
      <Support />
      <FooterSection className={className} />
      {isNavbar && <Navbar />}
    </>
  );
};

export default Footer;
