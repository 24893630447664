export const countryCodes = {
  Maldives: 'mv',
  Mozambique: 'mz',
  'New Caledonia': 'nc',
  'Russian Federation': 'ru',
  Chad: 'td',
  Martinique: 'mq',
  "Cote d'Ivoire": 'ci',
  'Holy See (Vatican City State)': 'va',
  'French Polynesia': 'pf',
  'United States Minor Outlying Islands': 'um',
  India: 'in',
  Bolivia: 'bo',
  Italy: 'it',
  'Kyrgyz Republic': 'kg',
  Portugal: 'pt',
  'French Guiana': 'gf',
  'Slovakia (Slovak Republic)': 'sk',
  Guadeloupe: 'gp',
  'Virgin Islands, British': 'vg',
  'British Indian Ocean Territory (Chagos Archipelago)': 'io',
  Andorra: 'ad',
  'Isle of Man': 'im',
  'Syrian Arab Republic': 'sy',
  Jordan: 'jo',
  Nicaragua: 'ni',
  Belize: 'bz',
  Switzerland: 'ch',
  Niue: 'nu',
  Lesotho: 'ls',
  Mayotte: 'yt',
  'United Arab Emirates': 'ae',
  Taiwan: 'tw',
  Guyana: 'gy',
  Panama: 'pa',
  Paraguay: 'py',
  'Hong Kong': 'hk',
  'Norfolk Island': 'nf',
  'Costa Rica': 'cr',
  Jersey: 'je',
  Gambia: 'gm',
  Sweden: 'se',
  Australia: 'au',
  Mauritius: 'mu',
  Montenegro: 'me',
  Chile: 'cl',
  'Virgin Islands, U.S.': 'vi',
  Croatia: 'hr',
  'New Zealand': 'nz',
  'Central African Republic': 'cf',
  Thailand: 'th',
  Azerbaijan: 'az',
  Afghanistan: 'af',
  "Lao People's Democratic Republic": 'la',
  Philippines: 'ph',
  'Saint Helena': 'sh',
  Anguilla: 'ai',
  Norway: 'no',
  Israel: 'il',
  'Solomon Islands': 'sb',
  Ghana: 'gh',
  Greenland: 'gl',
  Gibraltar: 'gi',
  'United Kingdom': 'gb',
};

export const phoneMasks = [
  { countryCode: 'mv', mask: '### ####', phoneCode: '+960' },
  { countryCode: 'mz', mask: '## ### ###', phoneCode: '+258' },
  { countryCode: 'nc', mask: '##-####', phoneCode: '+687' },
  { countryCode: 'ru', mask: '(###) ### ## ##', phoneCode: '+7' },
  { countryCode: 'td', mask: '## ## ## ##', phoneCode: '+235' },
  { countryCode: 'mq', mask: '(###) ## ## ##', phoneCode: '+596' },
  { countryCode: 'ci', mask: '## ### ###', phoneCode: '+225' },
  { countryCode: 'va', mask: '06 698#####', phoneCode: '+39' },
  { countryCode: 'pf', mask: '## ## ##', phoneCode: '+689' },
  { countryCode: 'um', mask: '(###) ### ####', phoneCode: '+1' },
  { countryCode: 'in', mask: '(####) ### ###', phoneCode: '+91' },
  { countryCode: 'bo', mask: '# ### ####', phoneCode: '+591' },
  { countryCode: 'it', mask: '(###) #### ###', phoneCode: '+39' },
  { countryCode: 'kg', mask: '(###) ### ###', phoneCode: '+996' },
  { countryCode: 'pt', mask: '## ### ####', phoneCode: '+351' },
  { countryCode: 'gf', mask: '#####-####', phoneCode: '+594' },
  { countryCode: 'sk', mask: '(###) ### ###', phoneCode: '+421' },
  { countryCode: 'gp', mask: '(###)###-###', phoneCode: '+590' },
  { countryCode: 'vg', mask: '(284)###-####', phoneCode: '+1' },
  { countryCode: 'io', mask: '### ####', phoneCode: '+246' },
  { countryCode: 'ad', mask: '### ###', phoneCode: '+376' },
  { countryCode: 'im', mask: '(####) ######', phoneCode: '+44' },
  { countryCode: 'sy', mask: '## #### ###', phoneCode: '+963' },
  { countryCode: 'jo', mask: '# #### ####', phoneCode: '+962' },
  { countryCode: 'ni', mask: '#### ####', phoneCode: '+505' },
  { countryCode: 'bz', mask: '### ####', phoneCode: '+501' },
  { countryCode: 'ch', mask: '## ### ####', phoneCode: '+41' },
  { countryCode: 'nu', mask: '####', phoneCode: '+683' },
  { countryCode: 'ls', mask: '# ### ####', phoneCode: '+266' },
  { countryCode: 'yt', mask: '#####-####', phoneCode: '+262' },
  { countryCode: 'ae', mask: '# ### ####', phoneCode: '+971' },
  { countryCode: 'tw', mask: '#### ####', phoneCode: '+886' },
  { countryCode: 'gy', mask: '### ####', phoneCode: '+592' },
  { countryCode: 'pa', mask: '### ####', phoneCode: '+507' },
  { countryCode: 'py', mask: '(###) ### ###', phoneCode: '+595' },
  { countryCode: 'hk', mask: '#### ####', phoneCode: '+852' },
  { countryCode: 'nf', mask: '3##-###', phoneCode: '+672' },
  { countryCode: 'cr', mask: '#### ####', phoneCode: '+506' },
  { countryCode: 'je', mask: '(####) #### ######', phoneCode: '+44' },
  { countryCode: 'gm', mask: '(###) ## ##', phoneCode: '+220' },
  { countryCode: 'se', mask: '## ### ####', phoneCode: '+46' },
  { countryCode: 'au', mask: '# #### ####', phoneCode: '+61' },
  { countryCode: 'mu', mask: '### ####', phoneCode: '+230' },
  { countryCode: 'me', mask: '## ### ###', phoneCode: '+382' },
  { countryCode: 'cl', mask: '# #### ####', phoneCode: '+56' },
  { countryCode: 'vi', mask: '(340) ### ####', phoneCode: '+1' },
  { countryCode: 'hr', mask: '## ### ###', phoneCode: '+385' },
  { countryCode: 'nz', mask: '# ### ###', phoneCode: '+64' },
  { countryCode: 'cf', mask: '## ## ####', phoneCode: '+236' },
  { countryCode: 'th', mask: '## ### ###', phoneCode: '+66' },
  { countryCode: 'az', mask: '## ### ## ##', phoneCode: '+994' },
  { countryCode: 'af', mask: '## ### ####', phoneCode: '+93' },
  { countryCode: 'la', mask: '## ### ###', phoneCode: '+856' },
  { countryCode: 'ph', mask: '(###) ### ####', phoneCode: '+63' },
  { countryCode: 'sh', mask: '####', phoneCode: '+290' },
  { countryCode: 'ai', mask: '(264) ### ####', phoneCode: '+1' },
  { countryCode: 'no', mask: '(###) ## ###', phoneCode: '+47' },
  { countryCode: 'il', mask: '# ### ####', phoneCode: '+972' },
  { countryCode: 'sb', mask: '#####', phoneCode: '+677' },
  { countryCode: 'gh', mask: '(###) ### ###', phoneCode: '+233' },
  { countryCode: 'gl', mask: '## ## ##', phoneCode: '+299' },
  { countryCode: 'gi', mask: '### #####', phoneCode: '+350' },
  { countryCode: 'gb', mask: '## #### ####', phoneCode: '+44' },
];
