import { InputProps } from '@ui/input';
import { Select } from '@components/form';
import type {
  TOnCurrencyChange,
  TSelectedCurrency,
} from '@components/form/select';
import { IBaseInputProps } from './types';
import { AmountWithDropdown } from './amount-with-dropdown';
import { forwardRef } from 'react';

type TAmountInputCurrencyProps = {
  isCurrencySelect?: boolean;
  currency?: TSelectedCurrency;
  onCurrencyChange?: TOnCurrencyChange;
};

export interface ICurrencyAmountProps
  extends IBaseInputProps,
    TAmountInputCurrencyProps {
  label?: string;

  slotProps?: {
    input?: InputProps;
    currencySelect?: Partial<Select.ConfirmableSelect.Ready.ICurrencySelectProps>;
  };
}

export const CurrencyAmount = forwardRef<
  HTMLInputElement,
  ICurrencyAmountProps
>(
  (
    {
      value,
      onChange,
      isCurrencySelect = true,
      slotProps,
      label,
      currency,
      onCurrencyChange,
      placeholder,
    },
    ref,
  ) => (
    <AmountWithDropdown
      value={value}
      onChange={onChange}
      label={label}
      placeholder={placeholder}
      slotProps={slotProps}
      ref={ref}
      dropdownButton={
        isCurrencySelect ? (
          <Select.ConfirmableSelect.Ready.CurrencySelect
            title={label}
            currency={currency}
            onCurrencyChange={onCurrencyChange}
            {...slotProps?.currencySelect}
          />
        ) : null
      }
    />
  ),
);
