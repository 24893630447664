import { useForm } from 'react-hook-form';

import { TSelectedCurrency } from '@components/form/select';
import { zodResolver } from '@hookform/resolvers/zod';
import { ECurrency } from '@lib/currency';
import { toastAxiosError } from '@shared/api';
import { displaySuccessForOfflineAction } from '@shared/lib';
import { z } from 'zod';

import { useCreateAccountTicket } from './queries';

const createSavingAccountSchema = z.object({
  name: z.string().min(1, 'Account name is required'),
  currency: z.nativeEnum(ECurrency, { required_error: 'Currency is required' }),
});

type TCreateSavingAccountFormValues = {
  name: string;
  currency: TSelectedCurrency;
};

export interface IUseCreateSavingAccountForm {
  onSuccess?: () => void;
}

export const useCreateSavingAccountForm = ({
  onSuccess,
}: IUseCreateSavingAccountForm = {}) => {
  const { createAccountTicket, isPending } = useCreateAccountTicket();

  const form = useForm<TCreateSavingAccountFormValues>({
    resolver: zodResolver(createSavingAccountSchema),
    defaultValues: {
      name: '',
      currency: undefined,
    },
  });

  const onSubmit = async (data: TCreateSavingAccountFormValues) => {
    if (!data.currency) {
      console.error('Currency is required');
      return;
    }

    try {
      await createAccountTicket({
        currency: data.currency,
        name: data.name,
      });

      onSuccess && onSuccess();
    } catch (error) {
      if (!navigator.onLine) {
        return displaySuccessForOfflineAction();
      } else {
        console.error('Failed to create saving account:', error);
        toastAxiosError({ error });
      }
    }

    form.reset();
  };

  return { form, onSubmit, isPending };
};
