import { PropsWithChildren } from 'react';

import { HeaderWrapper } from './header-wrapper';
import { LeftSide } from './left-side';

export const FullscreenModalHeader = ({ children }: PropsWithChildren) => {
  return (
    <HeaderWrapper>
      <LeftSide />
      {children}
    </HeaderWrapper>
  );
};
