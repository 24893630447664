import React from 'react';

import { IconProps } from './types';

export const ClockIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M5.70473 2.45797C8.76539 
      1.18997 12.2741 2.64397 
      13.5421 5.70464C14.8101 8.76531
       13.3561 12.274 10.2954 
       13.542C7.23473 14.81 3.72606 
       13.356 2.45806 10.2953C1.19073 
       7.23464 2.64406 3.72597 
       5.70473 2.45797"
      stroke="#6F767E"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.81201 5.32336V8.42403L10.2493 9.91003"
      stroke="#6F767E"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
