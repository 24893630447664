import { useContext } from 'react';

import { Input } from '@components/form';
import { FormLabel } from '@components/settings';
import { Button } from '@ui/button';
import { Loader } from '@ui/loader';
import { Check, X } from 'lucide-react';

import { SelectContext } from './provider';

export interface IListProps extends React.PropsWithChildren {
  title?: string;
  isLoading?: boolean;
  isSearchable?: boolean;

  slotProps?: {
    container?: React.HTMLAttributes<HTMLDivElement>;
  };
}

export const List = ({
  isSearchable = true,

  title,
  slotProps,
  isLoading,
  children,
}: IListProps) => {
  const { searchValue, onSearchChange, onAccept, onCancel } =
    useContext(SelectContext);

  return (
    <div {...slotProps?.container}>
      {!!title && <FormLabel label={title} />}
      <div className="rounded-lg bg-neutral07 p-2 font-medium">
        {isSearchable && (
          <Input.Search
            value={searchValue}
            className="mb-3 bg-neutral08"
            onChange={onSearchChange}
          />
        )}
        <div className="max-h-[23.75rem] overflow-y-auto text-neutral04">
          {isLoading ? <Loader /> : children || 'No data...'}
        </div>
      </div>
      <div className="mt-8 flex justify-between">
        <Button
          size="rounded-2xl"
          variant="secondary"
          className="h-[3.375rem] w-[8.9375rem] bg-neutral08"
          type="button"
          onClick={onCancel}
        >
          <X size={22} />
        </Button>
        <Button
          size="rounded-2xl"
          variant="secondary"
          className="h-[3.375rem] w-[8.9375rem] bg-neutral08"
          type="button"
          onClick={onAccept}
        >
          <Check size={22} />
        </Button>
      </div>
    </div>
  );
};
