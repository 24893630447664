import { IAccount } from '@entities/accounts/model';
import { ICard } from '@entities/cards/model';
import { IClientProfile } from '@entities/client/model';
import { client } from '@shared/api';
import {
  InfiniteData,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

export enum ETransactionType {
  EXCHANGE = 'EXCHANGE',
  DEPOSIT = 'DEPOSIT',
  WITHDRAW = 'WITHDRAW',
  CARD = 'CARD',
  INVOICE = 'INVOICE',
  TRANSFER = 'TRANSFER',
}

export enum ETransactionStatus {
  COMPLETE = 'COMPLETE',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

export enum EDirection {
  IN = 'in',
  OUT = 'out',
}

export interface IOperationAccount extends IAccount {
  client?: Pick<IClientProfile, 'id' | 'firstName' | 'lastName'>;
}

export interface IOperation {
  id: string;
  transactionId: string;
  type: ETransactionType;
  status: ETransactionStatus;
  cardId: string | null;
  merchantCode: string | null;
  merchantName: string | null;
  importedAt: string | null;
  dzingStatus: string | null;
  debitAccountId: string;
  debitAmount: string;
  debitAccountBalanceAfter: number;
  creditAccountId: string;
  creditAmount: string;
  externalAmount: number;
  externalCurrency: string;
  creditAccountBalanceAfter: number;
  createdAt: string;
  card: ICard | null;
  creditAccount: IOperationAccount;
  debitAccount: IOperationAccount;
  direction?: 'in' | 'out' | undefined;
  comment?: string | null;
}

export interface ITransactionGroup {
  id: string;
  createdAt: string;
  operations: IOperation[];
}

export interface IPaginatedTransactions {
  results: ITransactionGroup[];
  total: number;
}

export interface IGetTransactionsParams {
  sort?: 'asc' | 'desc';
  skip?: number;
  take?: number;
  accountIds?: string[];
  currencies?: string[];
  statuses?: Array<ETransactionStatus>;
  cardIds?: string[];
  direction?: 'in' | 'out';
  fromDate?: string;
  toDate?: string;
  query?: string;
  types?: ETransactionType[];
}

export const useGetTransaction = (
  transactionId: string,
  options?: Omit<
    UseQueryOptions<ITransactionGroup, AxiosError>,
    'queryKey' | 'queryFn'
  >,
) => {
  const query = useQuery<ITransactionGroup, AxiosError>({
    queryKey: ['transaction', transactionId],
    queryFn: () =>
      client
        .get(`/client/transactions/${transactionId}`)
        .then((res) => res.data),
    ...options,
  });

  return {
    transaction: query.data,
    ...query,
  };
};

export const queryKeyGetTransactions = 'transactions';
export const useGetTransactions = (
  params: IGetTransactionsParams = {},
  options?: Omit<
    UseInfiniteQueryOptions<
      IPaginatedTransactions,
      AxiosError,
      InfiniteData<IPaginatedTransactions>,
      IPaginatedTransactions,
      (string | IGetTransactionsParams)[],
      number
    >,
    'queryKey' | 'queryFn' | 'initialPageParam' | 'getNextPageParam'
  >,
) => {
  return useInfiniteQuery<
    IPaginatedTransactions,
    AxiosError,
    InfiniteData<IPaginatedTransactions>,
    (string | IGetTransactionsParams)[],
    number
  >({
    queryKey: [queryKeyGetTransactions, params],
    queryFn: ({ pageParam = 0 }) =>
      client
        .get('/client/transactions', {
          params: {
            ...params,
            skip: params.skip || pageParam * (params.take || 10),
          },
        })
        .then((res) => res.data),
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) => {
      const loadedItems = allPages.reduce(
        (total, page) => total + page.results.length,
        0,
      );
      return loadedItems < lastPage.total ? allPages.length : undefined;
    },
    ...options,
  });
};

export const useGetOperation = (
  operationId: string,
  options?: Omit<
    UseQueryOptions<IOperation, AxiosError>,
    'queryKey' | 'queryFn'
  >,
) => {
  const query = useQuery<IOperation, AxiosError>({
    queryKey: ['operation', operationId],
    queryFn: () =>
      client.get(`/client/operations/${operationId}`).then((res) => res.data),
    ...options,
  });

  return {
    operation: query.data,
    ...query,
  };
};
