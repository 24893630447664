import { cn } from '@lib/utils';

export interface IHeaderWrapper
  extends React.HTMLAttributes<HTMLHeadingElement> {}

export const HeaderWrapper = ({ children, className }: IHeaderWrapper) => {
  return (
    <header
      className={cn(
        `sticky left-0 right-0 top-0 z-40 flex h-20 w-full flex-shrink-0
          items-center justify-between overflow-hidden border-b border-neutral07
          bg-black px-6 md:h-24 md:pl-10 md:pr-9`,
        className,
      )}
    >
      {children}
    </header>
  );
};
