import { Check } from 'lucide-react';
import { CurrencyButton } from './currency-button';
import { cn } from '@lib/utils';
import { getCurrencyName } from '@lib/currency';
import { TSelectedCurrency } from '../select/types';

type ModalCurrencyButtonProps = {
  selected?: TSelectedCurrency;
  currency?: TSelectedCurrency;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

export const ModalCurrencyButton = ({
  selected,
  currency,
  onClick,
}: ModalCurrencyButtonProps) => {
  const isSelected = selected === currency;
  const currencyName = getCurrencyName(currency);

  return (
    <button
      key={currency}
      data-currency={currency}
      onClick={onClick}
      disabled={isSelected}
      className="rounded-xl"
    >
      <CurrencyButton
        currency={currency}
        currencyName={currencyName}
        className={cn('h-[3.75rem] pl-4 pr-3 text-[#6F767E]', {
          'text-inherit': isSelected,
        })}
        icon={isSelected ? <Check className="size-6" /> : null}
      />
    </button>
  );
};
