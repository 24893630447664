import { InputProps } from '@ui/input';
import { Select } from '@components/form';
import type {
  TOnAccountChange,
  TSelectedAccount,
} from '@components/form/select';
import { IBaseInputProps } from './types';
import { AmountWithDropdown } from './amount-with-dropdown';
import { forwardRef } from 'react';

type TAmountInputAccountProps = {
  isCurrencySelect?: boolean;
  account?: TSelectedAccount;
  onAccountChange?: TOnAccountChange;
};

export interface IAccountAmountProps
  extends IBaseInputProps,
    TAmountInputAccountProps {
  label?: string;
  hiddenAccounts?: string[];

  slotProps?: {
    input?: InputProps;
    accountSelect?: Partial<Select.ConfirmableSelect.Ready.IAccountSelectProps>;
  };
}

export const AccountAmount = forwardRef<HTMLInputElement, IAccountAmountProps>(
  (
    {
      value,
      onChange,
      isCurrencySelect = true,
      slotProps,
      label,
      account,
      onAccountChange,
      placeholder,
      hiddenAccounts,
    },
    ref,
  ) => (
    <AmountWithDropdown
      value={value}
      onChange={onChange}
      label={label}
      placeholder={placeholder}
      slotProps={slotProps}
      ref={ref}
      dropdownButton={
        isCurrencySelect ? (
          <Select.ConfirmableSelect.Ready.AccountSelect
            account={account}
            onAccountChange={onAccountChange}
            title={label}
            hiddenAccounts={hiddenAccounts}
            {...slotProps?.accountSelect}
          />
        ) : null
      }
    />
  ),
);
