import * as reactRouterDom from 'react-router-dom';

import {
  AccountDetailsPage,
  CheckingAccountsPage,
  CreateSavingAccountPage,
} from '@/pages/accounts';
import { CardDetailsPage } from '@/pages/cards';
import { RequestCardPage } from '@/pages/cards/request-card';
import { ConvertMoneyPage } from '@/pages/convert-money';
import { DashboardPage } from '@/pages/dashboard';
import { FAQPage } from '@/pages/faq';
import { NotificationsPage } from '@/pages/notifications';
import { SavingDetailsPage, SavingsAccountsPage } from '@/pages/savings';
import {
  EditProfilePage,
  NotificationSettings,
  PasswordPage,
  SecurityPage,
  SettingsLayout,
} from '@/pages/settings';
import { TopupPage } from '@/pages/topup';
import { TransactionsPage } from '@/pages/transactions';
import { WithdrawalPage } from '@/pages/withdrawal';
import { routesMap } from '@/shared/lib';
import { PreBuiltUIList } from '@app/config/supertokens';
import { ErrorPageWrapper } from '@components/error';
import ScrollToTop from '@components/scroll-to-top/ScrollToTop';
import { getSuperTokensRoutesForReactRouterDom } from 'supertokens-auth-react/ui';
import { ProtectedLayout } from '@app/providers/protected-layout';
import { SendMoneyPage } from '@pages/send-money';

const router = reactRouterDom.createBrowserRouter([
  ...reactRouterDom.createRoutesFromChildren(
    getSuperTokensRoutesForReactRouterDom(reactRouterDom, PreBuiltUIList),
  ),
  {
    path: routesMap.error,
    element: <ErrorPageWrapper />,
  },
  {
    path: routesMap.any,
    element: <reactRouterDom.Navigate to={routesMap.home} />,
  },
  {
    path: routesMap.home,
    element: (
      <>
        <ScrollToTop />
        <ProtectedLayout />
      </>
    ),
    errorElement: <ErrorPageWrapper />,
    children: [
      {
        path: '',
        element: <DashboardPage />,
      },
      {
        path: routesMap.convertMoney.short,
        element: <ConvertMoneyPage />,
      },
      {
        path: routesMap.topup.short,
        element: <TopupPage />,
      },
      {
        path: routesMap.withdraw.short,
        element: <WithdrawalPage />,
      },
      {
        path: routesMap.requestCard.short,
        element: <RequestCardPage />,
      },
      {
        path: routesMap.settings.short,
        element: <SettingsLayout />,
        children: [
          {
            path: '',
            element: (
              <reactRouterDom.Navigate to={routesMap.editProfile.full} />
            ),
          },
          {
            path: routesMap.editProfile.short,
            element: <EditProfilePage />,
          },
          {
            path: routesMap.security.short,
            element: <SecurityPage />,
          },
          {
            path: routesMap.password.short,
            element: <PasswordPage />,
          },
          {
            path: routesMap.settingsNotifications.short,
            element: <NotificationSettings />,
          },
        ],
      },
      {
        path: routesMap.savings.short,
        element: <SavingsAccountsPage />,
        children: [
          {
            path: routesMap.savingsCreateAccount.short,
            element: <CreateSavingAccountPage />,
          },
        ],
      },
      {
        path: routesMap.saving.short,
        element: <SavingDetailsPage />,
        children: [
          {
            path: routesMap.savingTopup.short,
            element: <TopupPage />,
          },
          {
            path: routesMap.savingWithdraw.short,
            element: <WithdrawalPage />,
          },
          {
            path: routesMap.savingConvertMoney.short,
            element: <ConvertMoneyPage />,
          },
        ],
      },
      {
        path: routesMap.card.short,
        element: <CardDetailsPage />,
        children: [
          {
            path: routesMap.cardRequestCard.short,
            element: <RequestCardPage />,
          },
        ],
      },
      {
        path: routesMap.accounts.short,
        element: <CheckingAccountsPage />,
        children: [
          {
            path: routesMap.accountsTopup.short,
            element: <TopupPage />,
          },
          {
            path: routesMap.accountsWithdraw.short,
            element: <WithdrawalPage />,
          },
          {
            path: routesMap.accountsConvertMoney.short,
            element: <ConvertMoneyPage />,
          },
        ],
      },
      {
        path: routesMap.account.short,
        element: <AccountDetailsPage />,
        children: [
          {
            path: routesMap.accountTopup.short,
            element: <TopupPage />,
          },
          {
            path: routesMap.accountWithdraw.short,
            element: <WithdrawalPage />,
          },
          {
            path: routesMap.accountConvertMoney.short,
            element: <ConvertMoneyPage />,
          },
        ],
      },
      {
        path: routesMap.notifications.short,
        element: <NotificationsPage />,
      },
      {
        path: routesMap.transactions.short,
        element: <TransactionsPage />,
      },
      {
        path: routesMap.faq.short,
        element: <FAQPage />,
      },
      {
        path: routesMap.sendMoney.short,
        element: <SendMoneyPage />,
      },
    ],
  },
]);

export default router;
