import * as React from 'react';

import { cn } from '@/lib/utils';
import { cva, type VariantProps } from 'class-variance-authority';

const badgeVariants = cva(
  'inline-flex items-center border transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
  {
    variants: {
      variant: {
        default:
          'border-transparent bg-primary text-primary-foreground hover:bg-[#1A1D1F]',
        secondary:
          'border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80',
        destructive:
          'border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80',
        outline: 'text-foreground',
        'negative-percentage':
          'border-[1px] border-neutral01 text-neutral01 opacity-30',
        'positive-percentage':
          'border-[1px] border-meaning-success/20 text-meaning-success',
        'neutral-status': 'bg-neutral07 text-neutral01/60 border-0',
      },
      size: {
        default: 'text-xs font-semibold px-2.5 py-0.5 rounded-full',
        sm: 'rounded-lg px-[0.3125rem] py-[0.1875rem] leading-[1.6] text-xs font-medium',
        xs: 'py-1 px-2 !leading-[16px] tracking-[1.6px] font-medium text-[8px] rounded-[4px]',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, size, ...props }: BadgeProps) {
  return (
    <div
      className={cn(badgeVariants({ variant, size }), className)}
      {...props}
    />
  );
}

export { Badge, badgeVariants };
