import CountryIcon from '@components/country-icon';
import { Select } from '@components/form';
import { TCountry } from '@entities/common/model';
import { EmptyIcon } from '../../common';

export interface ICountryItemProps {
  country: TCountry;
  isLoading: boolean;
}

export const CountryItem = ({ country, isLoading }: ICountryItemProps) => {
  return (
    <Select.ConfirmableSelect.Item value={country}>
      {isLoading ? (
        <div className="flex items-center gap-[10px]">
          <div className="h-5 w-5 rounded-full bg-[#33383F]"></div>
          <div className="h-3 w-[243px] rounded-full bg-[#33383F]"></div>
        </div>
      ) : (
        <>
          <CountryIcon
            country={country}
            className="size-5"
            emptyIcon={<EmptyIcon />}
          />
          <span>{country}</span>
        </>
      )}
    </Select.ConfirmableSelect.Item>
  );
};
