import Footer from '@components/layout/footer';
import { FullscreenModalHeader } from '@components/layout/header';
import { Button } from '@ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogOverlay,
  DialogTitle,
  DialogTrigger,
} from '@ui/dialog';
import CloseIcon from '@assets/icons/close-X.svg?react';
import { cn } from '@lib/utils';

export type TFullscreenModalProps = {
  trigger?: JSX.Element | React.ReactNode;
  topbar?: JSX.Element;
  slotProps?: {
    content?: React.HTMLAttributes<HTMLDivElement>;
  };
} & React.PropsWithChildren &
  React.ComponentProps<typeof Dialog>;

export const FullscreenModal = ({
  trigger,
  topbar,
  children,
  defaultOpen,
  open,
  onOpenChange,
  slotProps,
}: TFullscreenModalProps) => {
  return (
    <Dialog defaultOpen={defaultOpen} open={open} onOpenChange={onOpenChange}>
      {!!trigger && <DialogTrigger asChild>{trigger}</DialogTrigger>}
      <DialogContent
        className="flex flex-col !gap-0 overflow-y-auto !rounded-none
          border-none bg-black outline-none"
        sizeClassName="max-w-svw h-svh max-h-svh w-svw"
        overlay={
          <DialogOverlay className="h-full bg-black sm:h-full sm:w-full" />
        }
        close={null}
      >
        <DialogTitle hidden />
        {topbar || (
          <FullscreenModalHeader>
            <DialogClose asChild>
              <Button variant="outlined" size="icon-md">
                <CloseIcon className="size-4" />
              </Button>
            </DialogClose>
          </FullscreenModalHeader>
        )}
        <div
          {...slotProps?.content}
          className={cn(
            'flex flex-1 flex-col px-4',
            slotProps?.content?.className,
          )}
        >
          {children}
        </div>
        <Footer isNavbar={false} className="!relative mt-auto" />
      </DialogContent>
    </Dialog>
  );
};
