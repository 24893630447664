import { useEffect, useState } from 'react';

import { Modal } from '@components/modal';
import { useGetCurrencies } from '@entities/finance/model';
import { Loader } from '@ui/loader';

import { TOnCurrencyChange, TSelectedCurrency } from '../select/types';
import { ModalCurrencyButton } from './modal-currency-button';

export type SelectCurrencyModalProps = {
  onChange?: TOnCurrencyChange;
  selected?: TSelectedCurrency;
  title?: string;

  open?: boolean;
  setOpen?: (open: boolean) => void;
} & React.PropsWithChildren;

const CURRENCY_DATA_ATTRIBUTE = 'data-currency';
export const SelectCurrencyModal = ({
  selected,
  onChange,
  children,
  title,

  ...props
}: SelectCurrencyModalProps) => {
  const [open, setOpen] = useState(false);

  const { data, isLoading } = useGetCurrencies();
  const currencies = data?.currencies;

  const closeModal = () => {
    if (props.setOpen) {
      return props.setOpen(false);
    }

    setOpen(false);
  };

  const onCurrencyClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!onChange) return;

    const currency = e.currentTarget.getAttribute(
      CURRENCY_DATA_ATTRIBUTE,
    ) as TSelectedCurrency;

    onChange(currency || null);

    setTimeout(closeModal, 100);
  };

  useEffect(() => {
    if (typeof props.open === 'boolean') {
      setOpen(props.open);
    }
  }, [props.open]);

  return (
    <Modal
      title={title}
      trigger={children}
      open={open}
      onOpenChange={props.setOpen || setOpen}
    >
      <div className="flex max-h-[42vh] flex-col overflow-y-auto">
        {isLoading ? (
          <Loader />
        ) : (
          currencies?.map((currency, i) => {
            return (
              <ModalCurrencyButton
                key={`${currency}_${i}`}
                currency={currency}
                selected={selected}
                onClick={onCurrencyClick}
              />
            );
          }) || 'No data...'
        )}
      </div>
    </Modal>
  );
};
