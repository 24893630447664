import { lazy, Suspense } from 'react';

import { PageLoader } from '@components/ui/PageLoader';
import { FullscreenModalPage } from '@components/modal';

const SendMoneyPageLazy = lazy(() => import('./send-money-page'));

const SendMoneyPageAsync = () => {
  return (
    <Suspense
      fallback={
        <FullscreenModalPage>
          <PageLoader className="pt-5" />
        </FullscreenModalPage>
      }
    >
      <SendMoneyPageLazy />
    </Suspense>
  );
};

export { SendMoneyPageAsync as SendMoneyPage };
