// @ts-nocheck
import CountryIcon from '@components/country-icon';
import { FormMenu, Select } from '@components/form';
import { countryCodes, phoneMasks } from '@entities/country/lib';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import InputMask from 'react-input-mask';
import ChainlinkIcon from '@assets/icons/chainlink.svg';
import { ChevronDown } from 'lucide-react';
import { Label } from '@ui/label';
import { cn } from '@lib/utils';
import { fieldBase } from '@ui/lib';

interface PhoneInputProps extends React.ComponentProps<'input'> {
  resetValue?: () => void;
  onChangePhoneCode?: (value: string) => void;
}

const maskOptions = {
  maskChar: ' ',
  formatChars: {
    '#': '[0-9]',
    '?': '[0-9]',
  },
};

export const REGEX_PHONE =
  /^\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;

export const PhoneInput = forwardRef<HTMLInputElement, PhoneInputProps>(
  ({ resetValue, onChangePhoneCode, ...props }, ref) => {
    const [country, setCountry] = useState(Object.keys(countryCodes)[0]);

    const selectedCountry = useMemo(() => {
      return phoneMasks.find(
        (mask) =>
          mask.countryCode ===
          countryCodes[country as keyof typeof countryCodes],
      );
    }, [country]);

    const onSelectCountry = (country: Select.TSelectedCountry) => {
      setCountry(country ?? '');
      resetValue?.();
    };

    useEffect(() => {
      if (selectedCountry) {
        onChangePhoneCode?.(selectedCountry?.phoneCode);
      }
    }, [selectedCountry]);

    return (
      <Label
        className={cn(
          fieldBase,
          'ring-offset-background phone-input-container border-input focus-within:ring-ring grid grid-cols-[85px_1fr] gap-x-4 rounded-lg border-none bg-neutral07 px-4 py-3 focus-within:ring focus-within:ring-2 focus-within:ring-offset-2',
        )}
      >
        <Select.ConfirmableSelect.Ready.CountrySelect
          country={country}
          onCountryChange={onSelectCountry}
          renderTrigger={({ onListOpen }) => (
            <div
              className="flex h-full w-full cursor-pointer items-center"
              onClick={onListOpen}
            >
              <CountryIcon
                className="mr-3 size-6"
                country={country}
                emptyIcon={<FormMenu.Action.Icon src={ChainlinkIcon} />}
              />
              <span className="mr-1 text-sm leading-[150%]">
                {selectedCountry?.phoneCode}
              </span>
              <ChevronDown className="ml-auto min-w-4 text-neutral03" />
            </div>
          )}
        />

        <InputMask
          className="border-none bg-transparent
    text-sm outline-none placeholder:text-neutral01 placeholder:opacity-20"
          formatChars={maskOptions.formatChars}
          maskChar={maskOptions.maskChar}
          mask={selectedCountry?.mask ?? ''}
          inputRef={ref}
          placeholder={selectedCountry?.mask.replace(/#/gi, '0')}
          {...props}
        />
      </Label>
    );
  },
);
