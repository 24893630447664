import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { DashboardCard } from '@components/cards';
import { ICard, useGetCards } from '@entities/cards/model';
import { ITicket } from '@entities/tickets/model';
import CloseIcon from '@icons/CloseIcon';
import { NoCards } from '@icons/NoCards';
import { useScrollBehavior, useScrollPositionLocking } from '@shared/hooks';
import { routesMap } from '@shared/lib';
import { useStore } from '@shared/store';
import { Button } from '@ui/button';
import { Drawer, DrawerClose, DrawerContent, DrawerTitle } from '@ui/drawer';
import { Plus } from 'lucide-react';
import { useShallow } from 'zustand/react/shallow';

import { linkObj, links } from '../links';
import PendingDashboardCard from '@components/pending-card';

export interface INoCardsContentProps {
  isMobile?: boolean;
  pendingTickets?: ITicket[];
}

export const NoCardsContent: React.FC<INoCardsContentProps> = ({
  isMobile,
  pendingTickets = [],
}) => {
  const isTicketsPending = pendingTickets?.length > 0;
  const pendingTitle =
    pendingTickets?.length === 1
      ? 'Card is being created 🚀'
      : 'Cards are being created 🚀';

  const title = isTicketsPending
    ? pendingTitle
    : "You don't have any cards yet";
  const description = isTicketsPending
    ? "Let's make another card!"
    : 'Let’s make a new card!';

  return (
    <div className=" flex flex-col items-center justify-center gap-[36px]">
      <NoCards className="h-[190px] w-[200px]" />
      <div className="flex flex-col gap-[10px]">
        <div className="text-center text-[20px]">{title}</div>
        <div className="mx-auto text-center text-[16px]">{description}</div>
      </div>

      <Button size="xl" className="z-[999] w-full tracking-[0.01rem]" asChild>
        {isMobile ? (
          <Link to={routesMap.requestCard.short}>
            <span className="ml-2 text-base">Create and Order Card</span>
          </Link>
        ) : (
          <Link to={routesMap.requestCard.short}>
            <Plus />
            <span className="ml-2 text-base">Create a New Card</span>
          </Link>
        )}
      </Button>
    </div>
  );
};

const Navbar: FC = () => {
  const pathname = useLocation().pathname;
  const [selected, setSelected] = useState(links[0]);
  const { cards, pendingTickets } = useGetCards();
  const { isCardsOpen, setCardsNavbarOpen } = useStore(
    useShallow((state) => ({
      isCardsOpen: state.isCardsOpen,
      setCardsNavbarOpen: state.setCardsNavbarOpen,
    })),
  );
  const navigate = useNavigate();
  const isPendingTickets = pendingTickets.length > 0;
  const handleSelect = () => {
    const currentLink = links.find((link) => link.path === pathname);
    if (currentLink) {
      setSelected(currentLink);
    }
  };
  useEffect(handleSelect, [pathname]);

  const onOpenChange = (val: boolean) => {
    if (!val) handleSelect();
    if (isCardsOpen !== val) {
      setCardsNavbarOpen(val);
    }
  };

  const isIosStandalone = useMemo(
    () =>
      window.matchMedia('(display-mode: standalone)').matches &&
      /iPad|iPhone|iPod/.test(navigator.userAgent),
    [],
  );

  useScrollPositionLocking(isCardsOpen, isIosStandalone);

  useScrollBehavior(isCardsOpen, isIosStandalone);

  return (
    <Drawer open={isCardsOpen} onOpenChange={onOpenChange}>
      <div
        className="fixed bottom-[env(safe-area-inset-bottom)]
          left-[env(safe-area-inset-left)] right-[env(safe-area-inset-right)]
          grid place-content-center pb-3 md:hidden"
      >
        <div
          className="pointer-events-none absolute bottom-0
            left-0 h-[7.125rem] w-full"
          style={{
            background:
              'linear-gradient(0deg, #0A0A0A 16.67%, rgba(10, 10, 10, 0.00) 95.83%)',
          }}
        />
        <nav
          style={{ boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)' }}
          className="relative z-20 flex max-w-[310px] items-center justify-between gap-2 rounded-[50px] bg-neutral07 p-1"
        >
          <Slider selected={selected} />
          {links.map((link) => (
            <Chip
              key={link.title}
              linkObj={link}
              selected={selected === link}
              setSelected={setSelected}
              openDrawer={() => setCardsNavbarOpen(true)}
            />
          ))}
        </nav>
      </div>
      <DrawerContent className="z-[999999] h-[90%] w-full border-0 bg-neutral08">
        <div className="overflow-y-auto px-[28px] pb-[32px] pt-[9px]">
          <div className="mb-[25px] flex justify-between">
            <DrawerTitle className="mt-0 text-[20px] font-medium">
              My Cards
            </DrawerTitle>

            <DrawerClose asChild>
              <Button variant="outlined" size="icon-md">
                <CloseIcon className="size-4" />
              </Button>
            </DrawerClose>
          </div>
          {cards?.length > 0 ? (
            <div className="flex flex-col items-center justify-center gap-[32px]">
              {cards?.map((card: ICard) => (
                <DashboardCard
                  status={card.status}
                  key={card.number}
                  number={card.number}
                  onClick={() => {
                    navigate(`${routesMap.cards.full}/${card.id}`);
                    return setCardsNavbarOpen(false);
                  }}
                />
              ))}
              {pendingTickets?.map((card) => {
                return <PendingDashboardCard key={card.id} />;
              })}
              <Button
                size="xl"
                className="z-[999] w-full tracking-[0.01rem]"
                asChild
              >
                <Link to="request-card">
                  <span className="ml-2 text-base">
                    {isPendingTickets
                      ? 'Request another card'
                      : 'Create and Order Card'}
                  </span>
                </Link>
              </Button>
            </div>
          ) : (
            <NoCardsContent isMobile />
          )}
        </div>
      </DrawerContent>
    </Drawer>
  );
};

const Slider: FC<{ selected: linkObj }> = ({ selected }) => {
  const sliderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const slider = sliderRef.current;
    const selectedLink = document.querySelector(`.link-${selected.title}`);
    if (slider && selectedLink) {
      const { offsetLeft, offsetWidth } = selectedLink as HTMLElement;
      slider.style.left = `${offsetLeft}px`;
      slider.style.width = `${offsetWidth}px`;
    }
  }, [selected]);

  return (
    <div
      ref={sliderRef}
      className="absolute bottom-0 top-0 z-0 my-auto h-[54px] rounded-[50px] bg-neutral01 transition-all duration-300"
    />
  );
};

const Chip: FC<{
  linkObj: linkObj;
  selected: boolean;
  setSelected: Dispatch<SetStateAction<linkObj>>;
  openDrawer: () => void;
}> = ({ linkObj, selected, setSelected, openDrawer }) => {
  const handleClick = (e: React.MouseEvent) => {
    if (linkObj.path === routesMap.cards.full) {
      e.preventDefault();
      openDrawer();
    }
    setSelected(linkObj);
  };

  return (
    <Link
      to={linkObj.path}
      onClick={handleClick}
      className={`link-${linkObj.title} relative z-10 flex items-center px-6 py-4 ${
        selected
          ? 'text-neutral07 [&>svg:has(defs)>path]:fill-neutral07 [&>svg:has(g)>path]:stroke-neutral07 [&>svg]:z-[1]'
          : 'text-neutral02 active:bg-neutral08'
      } rounded-[50px] transition-colors duration-300`}
    >
      {linkObj.icon}
    </Link>
  );
};

export default Navbar;
