import { FC } from 'react';

import { IconProps } from './types';

const PercentageIcon: FC<IconProps> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      className={className}
    >
      <g></g>
      <path
        d="M8.25065 1.8335H13.7507C18.334 1.8335 20.1673 3.66683 20.1673 8.25016V13.7502C20.1673 18.3335 18.334 20.1668 13.7507 20.1668H8.25065C3.66732 20.1668 1.83398 18.3335 1.83398 13.7502V8.25016C1.83398 3.66683 3.66732 1.8335 8.25065 1.8335Z"
        stroke="#D1D5DB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.85547 13.9974L13.8504 8.00244"
        stroke="#D1D5DB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.23103 9.50597C8.85373 9.50597 9.35851 9.00119 9.35851 8.37849C9.35851 7.75579 8.85373 7.25098 8.23103 7.25098C7.60832 7.25098 7.10352 7.75579 7.10352 8.37849C7.10352 9.00119 7.60832 9.50597 8.23103 9.50597Z"
        stroke="#D1D5DB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.2271 14.7491C14.8498 14.7491 15.3546 14.2443 15.3546 13.6216C15.3546 12.9989 14.8498 12.4941 14.2271 12.4941C13.6044 12.4941 13.0996 12.9989 13.0996 13.6216C13.0996 14.2443 13.6044 14.7491 14.2271 14.7491Z"
        stroke="#D1D5DB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PercentageIcon;
