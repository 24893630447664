import { cn } from '@lib/utils';

export interface IFooterSectionProps {
  className?: string;
}

const FooterSection = ({ className }: IFooterSectionProps) => {
  return (
    <footer
      className={cn(
        `fixed bottom-0 left-0 right-0 hidden h-10 w-full flex-shrink-0
        items-center justify-between bg-neutral08 px-12 text-[10px] uppercase
        md:flex`,
        className,
      )}
    >
      <div className="flex gap-6">
        <a
          className="opacity-50 duration-300 hover:opacity-100"
          href="https://gcard.gg"
          target="_blank"
          rel="noreferrer"
        >
          GCARD®
        </a>
        <a
          className="opacity-50 duration-300 hover:opacity-100"
          href="https://g01.gg"
          target="_blank"
          rel="noreferrer"
        >
          G01 VIP Concierge Ltd
        </a>
      </div>
      <div className="flex gap-6">
        <a
          href="https://gcard.gg/terms"
          target="_blank"
          className="opacity-50 duration-300 hover:opacity-100"
          rel="noreferrer"
        >
          cookie policy
        </a>
        <a
          href="https://gcard.gg/terms"
          target="_blank"
          className="opacity-50 duration-300 hover:opacity-100"
          rel="noreferrer"
        >
          Data protection policy
        </a>
        <a
          href="https://gcard.gg/terms"
          target="_blank"
          className="opacity-50 duration-300 hover:opacity-100"
          rel="noreferrer"
        >
          Terms of Service & Deposit Account Agreement
        </a>
      </div>
    </footer>
  );
};

export default FooterSection;
