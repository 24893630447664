import React from 'react';

import { IconProps } from './types';

export const NoCards: React.FC<IconProps> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="200"
    height="191"
    viewBox="0 0 200 191"
    fill="none"
    className={className}
  >
    <path
      opacity="0.8"
      d="M154.423 3.73416L11.2921 55.5729C6.36058 57.359 3.80682 62.8155 5.58811 67.7602L35.7857 151.587C37.567 156.532 43.0088 159.093 47.9403 157.307L191.071 105.468C196.003 103.682 198.556 98.2254 196.775 93.2806L166.578 9.4535C164.796 4.50871 159.354 1.94808 154.423 3.73416Z"
      fill="#272B30"
    />
    <g filter="url(#filter0_b_8206_6424)">
      <path
        d="M159.883 59.459L9.3882 82.1188C4.20297 82.8995 0.630723 87.7472 1.40936 92.9464L14.6093 181.086C15.388 186.285 20.2226 189.867 25.4079 189.086L175.902 166.427C181.088 165.646 184.66 160.798 183.881 155.599L170.681 67.4593C169.903 62.2602 165.068 58.6783 159.883 59.459Z"
        fill="#181B1E"
        fillOpacity="0.8"
      />
      <path
        d="M159.883 59.459L9.3882 82.1188C4.20297 82.8995 0.630723 87.7472 1.40936 92.9464L14.6093 181.086C15.388 186.285 20.2226 189.867 25.4079 189.086L175.902 166.427C181.088 165.646 184.66 160.798 183.881 155.599L170.681 67.4593C169.903 62.2602 165.068 58.6783 159.883 59.459Z"
        stroke="#1A1D1F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_b_8206_6424"
        x="-9.19714"
        y="48.8521"
        width="203.685"
        height="150.841"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="5" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_8206_6424"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_8206_6424"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
