import { useMemo } from 'react';

import { ITicket } from '@entities/tickets/model';
import { client } from '@shared/api';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

export enum ECardStatus {
  active = 'active',
  inactive = 'inactive',
}

export interface ICard {
  id: string;
  pin: string | null;
  cvv: string;
  number: string;
  expiresAt: string;
  name: string;
  holder: string;
  status: ECardStatus;
}

export interface ICardUnmasked {
  response: string;
}

export interface IPaginatedCards {
  results: ICard[];
  pendingTickets?: ITicket[];
  total: number;
}

export interface IUpdateCardPayload {
  name: string;
}

export interface IGetCardsParams {
  sort?: 'asc' | 'desc';
  skip?: number;
  take?: number;
}

export const useGetCardUnmasked = (
  cardId: string,
  options?: Omit<UseQueryOptions<ICard, AxiosError>, 'queryKey' | 'queryFn'>,
) => {
  const query = useQuery<ICard, AxiosError>({
    queryKey: ['cardUnmasked', cardId],
    queryFn: async () => {
      const response = await client.get<ICardUnmasked>(
        `/client/cards/${cardId}/unmasked`,
      );
      const decodedData = JSON.parse(atob(response.data.response)) as ICard;
      return {
        ...decodedData,
        id: cardId,
      };
    },
    ...options,
  });

  return {
    unmaskedCard: query.data,
    ...query,
  };
};

export const useGetCard = (
  cardId: string,
  options?: Omit<UseQueryOptions<ICard, AxiosError>, 'queryKey' | 'queryFn'>,
) => {
  const query = useQuery<ICard, AxiosError>({
    queryKey: ['card', cardId],
    queryFn: () =>
      client.get(`/client/cards/${cardId}`).then((res) => res.data),
    ...options,
  });

  return {
    card: query.data,
    ...query,
  };
};

export const useGetCards = (
  params: IGetCardsParams = {},
  options?: Omit<
    UseQueryOptions<IPaginatedCards, AxiosError>,
    'queryKey' | 'queryFn'
  >,
) => {
  const query = useQuery<IPaginatedCards, AxiosError>({
    queryKey: ['cards', params],
    queryFn: () =>
      client.get('/client/cards', { params }).then((res) => res.data),
    ...options,
  });

  const cards = useMemo(
    () => query.data?.results || [],
    [query.data],
  ) as ICard[];
  const total = useMemo(() => query.data?.total || 0, [query.data]);
  const pendingTickets = useMemo(
    () => query.data?.pendingTickets || [],
    [query.data],
  );

  return {
    cards,
    total,
    pendingTickets,
    ...query,
  };
};
