import React from 'react';

import ArrowLeftIcon from '@assets/icons/arrow-left.svg?react';
import ChainlinkIcon from '@assets/icons/chainlink.svg';
import { motion } from 'framer-motion';

export interface IActionMenuButtonProps {
  icon?: JSX.Element | null;
  label: string;
  value?: string;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

type Ref = React.ForwardedRef<HTMLButtonElement>;

export const Button = React.forwardRef(
  (
    { icon, label, value, disabled, onClick, ...props }: IActionMenuButtonProps,
    ref: Ref,
  ) => (
    <button
      className="group flex w-full
      items-center gap-4 border-card-stroke/20 p-4 text-left
      font-medium transition-opacity hover:opacity-80 active:opacity-100
      disabled:pointer-events-none last:[&:not(:first-child)]:rounded-b-lg
      [&:not(:last-child)]:border-b-2 first:[&:not(:last-child)]:rounded-t-lg"
      type="button"
      disabled={disabled}
      onClick={onClick}
      ref={ref}
      {...props}
    >
      <div
        className="flex h-12 w-12 shrink-0 items-center justify-center
        rounded-full bg-neutral07"
      >
        {icon ? (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            {icon}
          </motion.div>
        ) : (
          <Icon src={ChainlinkIcon} />
        )}
      </div>
      <div>
        <span className="text-sm leading-normal text-neutral03/50">
          {label}
        </span>
        {!!value && (
          <motion.p
            initial={{ y: -10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            className="text-base leading-normal"
          >
            {value}
          </motion.p>
        )}
      </div>
      <div className="ml-auto group-disabled:opacity-30">
        <ArrowLeftIcon className="rotate-180 opacity-50" />
      </div>
    </button>
  ),
);

export const Menu = ({ children }: React.PropsWithChildren) => (
  <div className="flex flex-col rounded-lg border-2 border-card-stroke/20">
    {children}
  </div>
);

export const Icon = (props: React.ImgHTMLAttributes<HTMLImageElement>) => (
  <img loading="lazy" className="opacity-50" {...props} alt="icon" />
);
