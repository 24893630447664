/* eslint-disable no-fallthrough */
import {
  ENotificationDestination,
  ENotificationType,
  INotification,
  TicketStatus,
  TicketType,
} from '@entities/notifications/model';
import { ETransactionStatus } from '@entities/transactions';

export enum NotificationSectionKey {
  CARD_MANAGEMENT = 'CARD_MANAGEMENT',
  CUSTOMER_SUPPORT = 'CUSTOMER_SUPPORT',
  SECURITY = 'SECURITY',
  ACCOUNT_MANAGEMENT = 'ACCOUNT_MANAGEMENT',
}

export const NOTIFICATION_SECTION_TITLES: Record<
  NotificationSectionKey,
  string
> = {
  [NotificationSectionKey.CARD_MANAGEMENT]: 'Card Management',
  [NotificationSectionKey.CUSTOMER_SUPPORT]: 'Customer Support',
  [NotificationSectionKey.SECURITY]: 'Security',
  [NotificationSectionKey.ACCOUNT_MANAGEMENT]: 'Account Management',
};

export const NOTIFICATION_TYPES: Record<
  NotificationSectionKey,
  Array<{ type: ENotificationType; title: string; description: string }>
> = {
  [NotificationSectionKey.CARD_MANAGEMENT]: [
    {
      type: ENotificationType.CARD_CREATED,
      title: 'Card Issued',
      description: 'Alert when a new card is issued',
    },
    {
      type: ENotificationType.CARD_OPERATION,
      title: 'Card Transactions',
      description: 'Updates on card transactions',
    },
    {
      type: ENotificationType.REFUND_PROCESSED,
      title: 'Refund Processed',
      description: 'Notification when a refund is processed',
    },
  ],
  [NotificationSectionKey.CUSTOMER_SUPPORT]: [
    {
      type: ENotificationType.TICKET_STATUS_CHANGED,
      title: 'Support Ticket Status',
      description: 'Updates when the status of your support ticket changes',
    },
    {
      type: ENotificationType.MESSAGE_CREATED,
      title: 'New Support Message',
      description: 'Alerts for new support messages',
    },
  ],
  [NotificationSectionKey.SECURITY]: [
    {
      type: ENotificationType.FAILED_LOGIN,
      title: 'Failed Login Attempt',
      description: 'Alerts about unsuccessful login attempts',
    },
    {
      type: ENotificationType.LOGIN,
      title: 'Successful Login',
      description: 'Notification for successful logins',
    },
  ],
  [NotificationSectionKey.ACCOUNT_MANAGEMENT]: [
    {
      type: ENotificationType.ACCOUNT_CREATED,
      title: 'Account Creation',
      description: 'Get notified when a new account is created',
    },
    {
      type: ENotificationType.PASSWORD_RESET,
      title: 'Password Reset',
      description: 'Alert for password reset requests',
    },
    {
      type: ENotificationType.PASSWORD_UPDATED,
      title: 'Password Change',
      description: 'Notification for password changes',
    },
  ],
};

export const getUpdatedDestinations = (
  currentDestinations: ENotificationDestination[],
  enabled: boolean,
): ENotificationDestination[] => {
  if (enabled) {
    return Array.from(
      new Set([...currentDestinations, ENotificationDestination.TELEGRAM]),
    );
  }

  return currentDestinations.filter(
    (d) => d !== ENotificationDestination.TELEGRAM,
  );
};

export const getNotificationTitle = (notification: INotification) => {
  switch (notification.type) {
    case ENotificationType.ACCOUNT_CREATED: {
      return 'Account has been activated';
    }
    case ENotificationType.CARD_CREATED: {
      return 'Card has been activated';
    }
    case ENotificationType.CARD_OPERATION: {
      return `${notification.cardOperation?.merchantName ?? 'No merchant name'}`;
    }
    case ENotificationType.REFUND_PROCESSED: {
      return 'Refund has been processed';
    }
    case ENotificationType.FAILED_LOGIN: {
      return 'Failed login attempt';
    }
    case ENotificationType.LOGIN: {
      return 'Successful login';
    }
    case ENotificationType.PASSWORD_RESET: {
      return 'Password had been reset';
    }
    case ENotificationType.PASSWORD_UPDATED: {
      return 'Password had been updated';
    }
    case ENotificationType.TICKET_STATUS_CHANGED: {
      switch (notification.ticketStatusChangedStatus) {
        case TicketStatus.CLOSED_BY_ADMIN: {
          switch (notification.ticketStatusChangedTicket?.type) {
            case TicketType.DEPOSIT_CASH: {
              return 'Deposit request has been canceled';
            }
            case TicketType.WITHDRAW_CASH: {
              return 'Withdraw request has been canceled';
            }
            case TicketType.CREATE_ACCOUNT: {
              return 'Account request has been canceled';
            }
            case TicketType.CREATE_CARD: {
              return 'Card request has been canceled';
            }
          }
        }
        case TicketStatus.PROCESSED: {
          switch (notification.ticketStatusChangedTicket?.type) {
            case TicketType.DEPOSIT_CASH: {
              return 'Deposit request has been processed';
            }
            case TicketType.WITHDRAW_CASH: {
              return 'Withdraw request has been processed';
            }
            case TicketType.CREATE_ACCOUNT: {
              return 'Account request has been processed';
            }
            case TicketType.CREATE_CARD: {
              return 'Card request has been processed';
            }
          }
        }
      }
      return ''; // other ticket types are not expected
    }
    case ENotificationType.MESSAGE_CREATED: {
      return 'New message from your manager';
    }
  }
};

export const clickForDetailsDescription = 'Click for details';

export const getNotificationDetails = (notification: INotification) => {
  switch (notification.type) {
    case ENotificationType.ACCOUNT_CREATED: {
      return `${notification.accountCreatedAccount?.address?.slice(0, 4)} ${notification.accountCreatedAccount?.address?.slice(4)} (${notification.accountCreatedAccount?.currency})`;
    }
    case ENotificationType.CARD_CREATED: {
      return notification.cardCreatedCard?.number
        ? `**** ${notification.cardCreatedCard?.number}`
        : 'No card number';
    }
    case ENotificationType.CARD_OPERATION: {
      const number = notification.cardOperation?.card?.number;
      const amount = notification.cardOperation?.externalAmount;
      const currency = notification.cardOperation?.externalCurrency;
      const formattedAmount = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
      }).format(amount);
      return `${number ? `CARD **** ${number}` : 'No card number'}, ${formattedAmount}${notification.cardOperation?.status === ETransactionStatus.REJECTED ? ' (rejected)' : ''}`;
    }
    case ENotificationType.REFUND_PROCESSED: {
      const number = notification.refundProcessedOperation?.card?.number;
      const amount = notification.refundProcessedOperation?.externalAmount;
      const currency = notification.refundProcessedOperation?.externalCurrency;
      const formattedAmount = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
      }).format(amount);
      return `${number ? `CARD **** ${number}` : 'No card number'}, ${formattedAmount}${notification.refundProcessedOperation?.status === ETransactionStatus.REJECTED ? ' (rejected)' : ''}`;
    }
    case ENotificationType.FAILED_LOGIN: {
      return notification.failedLoginIp
        ? `IP: ${notification.failedLoginIp}`
        : 'No IP';
    }
    case ENotificationType.LOGIN: {
      return notification.loginIp ? `IP: ${notification.loginIp}` : 'No IP';
    }
    case ENotificationType.PASSWORD_RESET: {
      return 'Security';
    }
    case ENotificationType.PASSWORD_UPDATED: {
      return 'Security';
    }
    case ENotificationType.TICKET_STATUS_CHANGED: {
      return clickForDetailsDescription;
    }
    case ENotificationType.MESSAGE_CREATED: {
      return `${notification.messageCreatedMessage?.text ?? 'No message'}`;
    }
  }
};
