import { CurrencyIcon } from '@components/currency-icon';
import { Select } from '@components/form';
import { getCurrencyName } from '@lib/currency';
import { EmptyIcon } from '../../common';
import { cn } from '@lib/utils';

export interface ICurrencyItemContentProps {
  currency?: Select.TSelectedCurrency;
  message?: string;
}

export const CurrencyItemContent = ({
  currency,
  message,
}: ICurrencyItemContentProps) => {
  const getDisplayingName = () => `${getCurrencyName(currency)} (${currency})`;

  return (
    <>
      {!!currency && (
        <CurrencyIcon
          className="size-5"
          currency={currency}
          emptyIcon={<EmptyIcon />}
        />
      )}
      <span
        className={cn('text-sm font-medium', {
          'opacity-20': !currency,
        })}
      >
        {message ? message : getDisplayingName()}
      </span>
    </>
  );
};
