export enum ESize {
  default = 'default',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
}

export type TSizeVariant = Record<keyof typeof ESize, string>;

export const getComparedSizes = (
  initialSizes: TSizeVariant,
  ...args: TSizeVariant[]
): TSizeVariant =>
  Object.entries(initialSizes).reduce((acc, [key, initialValue]) => {
    const newValues = args.reduce(
      (acc, v) => (v[key as ESize] ? `${acc} ${v[key as ESize]}` : acc),
      '',
    );

    return {
      ...acc,
      [key]: `${initialValue} ${newValues}`,
    };
  }, {} as TSizeVariant);

export const FIELD_HEIGHT = {
  [ESize.default]: 2.5,
  [ESize.md]: 3,
  [ESize.lg]: 3.25,
  [ESize.xl]: 3.75,
};

export const TEXTAREA_MAX_HEIGHT = {
  [ESize.default]: 15.9375,
  [ESize.md]: 15.9375,
  [ESize.lg]: 15.9375,
  [ESize.xl]: 15.9375,
};

export const textareaMaxHeight = Object.entries(TEXTAREA_MAX_HEIGHT).reduce(
  (acc, [key, value]) => ({
    ...acc,
    [key as ESize]: `max-h-[${value}rem]`,
  }),
  {} as TSizeVariant,
);

export const fieldPaddings: TSizeVariant = {
  [ESize.default]: ' px-3 py-2 ',
  [ESize.md]: ' p-3 ',
  [ESize.lg]: ' px-3.5 py-4 ',
  [ESize.xl]: ' px-[1rem] py-[1.125rem] ',
};

export const textareaPaddings: TSizeVariant = {
  ...fieldPaddings,
  lg: ' px-3.5 pt-[1.125rem] pb-[1.0625rem] ',
};

export const fieldCommonSizes: TSizeVariant = {
  [ESize.default]: 'h-[2.5rem] rounded-md text-sm',
  [ESize.md]: 'h-[3rem] rounded-lg text-sm placeholder:text-sm',
  [ESize.lg]: 'h-[3.25rem] rounded-xl text-sm placeholder:text-sm',
  [ESize.xl]: 'h-[3.75rem] text-base rounded-lg',
};

export const fieldSizes = getComparedSizes(fieldCommonSizes, fieldPaddings);
export const textareaSizes = getComparedSizes(
  fieldCommonSizes,
  textareaPaddings,
  textareaMaxHeight,
);

export const fieldVariants = {
  dark: `border-none bg-neutral07 aria-[invalid=true]:shadow-error-input
    placeholder:text-neutral01 placeholder:opacity-20`,
  darkest: `border-none bg-neutral08 aria-[invalid=true]:shadow-error-input
    placeholder:text-neutral01 placeholder:opacity-20`,
};

export const fieldBase = `focus-visible:ring-ring bg-background ring-offset-background border-input
  file:border-0 file:bg-transparent file:text-sm file:font-medium
  placeholder:text-muted-foreground disabled:opacity-50
  flex w-full border focus-visible:outline-none
  focus-visible:ring-2 focus-visible:ring-offset-2
  disabled:cursor-not-allowed`;
