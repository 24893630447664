import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogOverlay,
  DialogTitle,
  DialogTrigger,
} from '@ui/dialog';
import { X } from 'lucide-react';

export type ModalProps = {
  title?: string;
  trigger?: JSX.Element | React.ReactNode;
} & React.PropsWithChildren &
  React.ComponentProps<typeof Dialog>;

export const Modal = ({
  title,
  trigger,
  children,
  defaultOpen,
  open,
  onOpenChange,
}: ModalProps) => {
  return (
    <Dialog defaultOpen={defaultOpen} open={open} onOpenChange={onOpenChange}>
      {!!trigger && <DialogTrigger asChild>{trigger}</DialogTrigger>}
      <DialogContent
        className="!h-auto max-w-[min(318px,_calc(100%_-_72px))]
          rounded-[8px] border-none bg-[#1A1D1F] px-3 py-2 outline-none md:max-w-[360px]"
        overlay={
          <DialogOverlay className="h-full bg-[#111315] sm:h-full sm:w-full">
            <DialogClose
              className="fixed right-6 top-[14px] flex size-9
                transform-none items-center justify-center rounded-full
                bg-[#272B30] transition-opacity hover:opacity-80"
            >
              <X className="size-6" />
              <span className="sr-only">Close</span>
            </DialogClose>
          </DialogOverlay>
        }
        close={null}
      >
        {!!title && (
          <DialogHeader className="absolute top-[-60px] max-w-full text-left">
            <DialogTitle className="truncate font-medium">{title}</DialogTitle>
          </DialogHeader>
        )}
        {children}
      </DialogContent>
    </Dialog>
  );
};
