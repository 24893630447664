import { useMemo } from 'react';

import { ITicket } from '@entities/tickets/model';
import { ECurrency } from '@lib/currency';
import { client } from '@shared/api';
import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

export enum EAccountType {
  CHECKING = 'CHECKING',
  SAVING = 'SAVING',
}

export interface IAccount {
  id: string;
  balance: number;
  currency: ECurrency;
  name: string;
  createdAt: string;
  address: string;
  priority: number | null;
  type: EAccountType;
  chart: number[];
}

export interface IPaginatedAccounts {
  results: IAccount[];
  pendingTickets?: ITicket[];
  total: number;
}

export interface ISetPriorityPayload {
  accountIds: string[];
}

export interface IUpdateAccountPayload {
  name: string;
}

export interface IGetAccountsParams {
  sort?: 'asc' | 'desc';
  take?: number;
  skip?: number;
  currencies?: string[];
  type?: 'CHECKING' | 'SAVING';
}

export const useSetAccountPriority = () => {
  const mutation = useMutation<void, AxiosError, ISetPriorityPayload>({
    mutationFn: (payload) =>
      client
        .post('/client/accounts/set-priority', payload)
        .then((res) => res.data),
  });

  return {
    setPriority: mutation.mutateAsync,
    ...mutation,
  };
};

export const queryKeyGetAccount = 'account';
export const useGetAccount = (
  accountId: string,
  options?: Omit<UseQueryOptions<IAccount, AxiosError>, 'queryKey' | 'queryFn'>,
) => {
  const query = useQuery<IAccount, AxiosError>({
    queryKey: [queryKeyGetAccount, accountId],
    queryFn: () =>
      client.get(`/client/accounts/${accountId}`).then((res) => res.data),
    ...options,
  });

  return {
    account: query.data,
    ...query,
  };
};

export const queryKeyGetAccounts = 'accounts';
export const useGetAccounts = (
  params: IGetAccountsParams = {},
  options?: Omit<
    UseQueryOptions<IPaginatedAccounts, AxiosError>,
    'queryKey' | 'queryFn'
  >,
) => {
  const query = useQuery<IPaginatedAccounts, AxiosError>({
    queryKey: [queryKeyGetAccounts, params],
    queryFn: () =>
      client.get('/client/accounts', { params }).then((res) => res.data),
    ...options,
  });

  const checkingAccounts = useMemo(
    () =>
      query.data?.results.filter((account) => account.priority !== null) || [],
    [query.data],
  );
  const savingAccounts = useMemo(
    () =>
      query.data?.results.filter((account) => account.priority === null) || [],
    [query.data],
  );
  const total = useMemo(() => query.data?.total || 0, [query.data]);

  const pendingTickets = useMemo(
    () => query.data?.pendingTickets || [],
    [query.data],
  );

  return {
    checkingAccounts,
    savingAccounts,
    pendingTickets,
    total,
    ...query,
  };
};
