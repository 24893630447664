import { ComponentProps, PropsWithChildren } from 'react';

import { cn } from '@lib/utils';
import { Badge } from '@ui/badge';

const TransactionBadge = ({
  children,
  className,
  ...props
}: PropsWithChildren & ComponentProps<typeof Badge>) => {
  return (
    <Badge
      className={cn(
        'whitespace-nowrap rounded-[0.25rem] bg-neutral08 px-2 py-1 text-[0.5rem] font-medium uppercase tracking-[0.1rem] text-neutral04',
        className,
      )}
      {...props}
    >
      {children}
    </Badge>
  );
};

export default TransactionBadge;
