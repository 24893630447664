import { ChevronDown } from 'lucide-react';
import { useContext } from 'react';
import { SelectContext } from './provider';

export interface IDropdownButtonProps extends React.PropsWithChildren {
  onClick?: () => void;
}

export const DropdownButton = ({ onClick, children }: IDropdownButtonProps) => {
  const { onListOpen } = useContext(SelectContext);

  return (
    <button
      className="flex h-full items-center pl-2 pr-5 text-sm"
      type="button"
      onClick={onClick || onListOpen}
    >
      {children}
      <ChevronDown size={16} className="text-neutral03" />
    </button>
  );
};
