// TODO: Refactor

import { useNavigate } from 'react-router-dom';
import { FullscreenModal, TFullscreenModalProps } from './fullscreen-modal';

export interface IFullscreenModalPageProps
  extends Partial<TFullscreenModalProps> {
  onExit?: () => void;
}

export const FullscreenModalPage = ({
  children,
  onExit,
  ...modalProps
}: IFullscreenModalPageProps) => {
  const navigate = useNavigate();

  // Temporarily
  const onOpenChange = (open: boolean) => {
    !open &&
      setTimeout(() => {
        if (onExit) {
          return onExit();
        }

        navigate('..');
      }, 100);
  };

  return (
    <FullscreenModal defaultOpen onOpenChange={onOpenChange} {...modalProps}>
      {children}
    </FullscreenModal>
  );
};
