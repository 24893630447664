import { countryCodes } from '@entities/country/lib';
import { cn } from '@lib/utils';
import { useState } from 'react';

const CountryIcon = ({
  country,
  emptyIcon,
  style,
  className,
  ...props
}: {
  country?: string;
  emptyIcon?: JSX.Element;
} & React.SVGAttributes<any>) => {
  const [isError, setIsError] = useState(false);

  //@ts-ignore
  const countryCode = countryCodes[country];
  const flagUrl = `https://flagcdn.com/w80/${countryCode}.webp`;

  if (isError || !country || !countryCode) {
    return emptyIcon || null;
  }

  return (
    <img
      src={flagUrl}
      width={20}
      height={20}
      loading="lazy"
      alt={`${country} flag`}
      onError={setIsError.bind(null, true)}
      className={cn('relative rounded-full', className)}
      {...props}
    />
  );
};

export default CountryIcon;
