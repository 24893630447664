import { FC } from 'react';

import { IconProps } from './types';

const WalletIcon: FC<IconProps> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      className={className}
    >
      <defs></defs>
      <path
        d="M15.584 20.854H6.41732C3.26398 20.854 1.14648 18.7365 1.14648 15.5832V10.9998C1.14648 8.1765 2.88815 6.13234 5.59232 5.79318C5.84898 5.75651 6.13315 5.729 6.41732 5.729H15.584C15.804 5.729 16.0882 5.73817 16.3815 5.784C19.0857 6.09567 20.8548 8.149 20.8548 10.9998V15.5832C20.8548 18.7365 18.7373 20.854 15.584 20.854ZM6.41732 7.104C6.19732 7.104 5.98648 7.12233 5.77565 7.14983C3.75898 7.40649 2.52148 8.87317 2.52148 10.9998V15.5832C2.52148 17.9482 4.05232 19.479 6.41732 19.479H15.584C17.949 19.479 19.4798 17.9482 19.4798 15.5832V10.9998C19.4798 8.85484 18.224 7.37901 16.189 7.14068C15.969 7.10401 15.7765 7.104 15.584 7.104H6.41732Z"
        fill="#D1D5DB"
      />
      <path
        d="M5.67405 7.15851C5.45405 7.15851 5.25238 7.05768 5.11488 6.87435C4.95905 6.66351 4.94071 6.38851 5.05988 6.15935C5.21571 5.84768 5.43571 5.54518 5.71988 5.27018L8.69905 2.28184C10.2207 0.769336 12.6957 0.769336 14.2174 2.28184L15.8215 3.90435C16.4999 4.57352 16.9124 5.47185 16.9582 6.42519C16.9674 6.63602 16.8849 6.83767 16.729 6.97516C16.5732 7.11267 16.3624 7.17685 16.1607 7.14019C15.9774 7.11269 15.7849 7.10351 15.5832 7.10351H6.41655C6.19655 7.10351 5.98571 7.12183 5.77488 7.14933C5.74738 7.1585 5.71071 7.15851 5.67405 7.15851ZM7.20488 5.72851H15.4182C15.299 5.41685 15.1065 5.13269 14.8499 4.87602L13.2365 3.24435C12.2557 2.27269 10.6515 2.27269 9.66155 3.24435L7.20488 5.72851Z"
        fill="#D1D5DB"
      />
      <path
        d="M20.1673 15.8127H17.4173C16.024 15.8127 14.8965 14.6852 14.8965 13.2918C14.8965 11.8985 16.024 10.771 17.4173 10.771H20.1673C20.5432 10.771 20.8548 11.0827 20.8548 11.4585C20.8548 11.8343 20.5432 12.146 20.1673 12.146H17.4173C16.7848 12.146 16.2715 12.6593 16.2715 13.2918C16.2715 13.9243 16.7848 14.4377 17.4173 14.4377H20.1673C20.5432 14.4377 20.8548 14.7493 20.8548 15.1252C20.8548 15.501 20.5432 15.8127 20.1673 15.8127Z"
        fill="#D1D5DB"
      />
    </svg>
  );
};

export default WalletIcon;
