import { CSSProperties } from 'react';
import { useLocation } from 'react-router-dom';

import { Button } from '@/components/ui/button';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import SupportServiceIcon from '@/icons/SupportServiceIcon';
import { useTelegramLink } from '@entities/common/model';
import { Computer } from '@icons/Computer';
import { MobilePhone } from '@icons/MobilePhone';
import { TelegramIcon } from '@icons/TelegramIcon';
import { useBreakpoint } from '@shared/hooks/use-breakpoint';
import { routesMap } from '@shared/lib';
import { useStore } from '@shared/store';
import { Skeleton } from '@ui/skeleton';
import QRCode from 'qrcode.react';
import { useShallow } from 'zustand/react/shallow';

export const NonDesktopSupportContent = () => {
  const { isPending, openTelegramBot } = useTelegramLink();

  return (
    <div className="grid">
      <div className="space-y-2">
        <h4 className="mb-[32px] text-[24px] font-medium leading-none text-neutral01">
          Here to help 24/7
        </h4>
      </div>

      <div className="text-[16px] font-medium text-neutral03">
        Wow... Now we&apos;re even closer, right in your messenger!
      </div>

      <Button
        className="mt-[32px] w-full"
        onClick={openTelegramBot}
        disabled={isPending}
        data-test="open-telegram-button"
      >
        <TelegramIcon className="mr-[8px] h-[28px] w-[28px]" />
        Open Telegram
      </Button>
    </div>
  );
};

const DesktopSupportPopover = () => {
  const { telegramUrl, isPending, openTelegramBot } = useTelegramLink();

  return (
    <PopoverContent className="shadow-[0px 24px 64px 0px rgba(0, 0, 0, 0.15)] mb-[24px] mr-[30px] max-h-[calc(81.5dvh-25px)] overflow-y-auto rounded-2xl border-0 bg-neutral07 px-[28px] py-[32px] lg:w-[370px]">
      <div className="grid gap-8">
        <div className="space-y-2">
          <h4 className="text-[24px] font-medium leading-none text-neutral01">
            Here to help 24/7
          </h4>
        </div>
        <div>
          <div className="flex gap-[24px]">
            <MobilePhone />
            <div className="flex flex-col">
              <p className="mb-[10px] text-[16px] font-medium text-neutral01">
                Chat on your mobile:
              </p>
              <p className="text-[14px] font-medium text-neutral03">
                Scan the QR code below with your smartphone camera
              </p>
            </div>
          </div>
          <div className="ml-auto mt-7 flex h-fit w-fit justify-center rounded-3xl bg-neutral01 p-[30px]">
            {isPending ? (
              <Skeleton className="size-[214px] bg-neutral02/80" />
            ) : (
              <QRCode value={telegramUrl} size={214} />
            )}
          </div>
        </div>
        <div>
          <div className="flex gap-[24px]">
            <Computer />
            <div className="flex flex-col">
              <p className="mb-[10px] text-[16px] font-medium text-neutral01">
                Chat on this computer:
              </p>
              <p className="text-[14px] font-medium text-neutral03">
                If you&apos;re logged into the messenger in this browser or app
                on this device
              </p>
            </div>
          </div>
          <Button
            className="mt-7 w-full"
            onClick={openTelegramBot}
            disabled={isPending}
            data-test="open-telegram-button"
          >
            Open Telegram
          </Button>
        </div>
      </div>
    </PopoverContent>
  );
};

const TabletSupportPopover = () => {
  const { isPending, openTelegramBot } = useTelegramLink();

  return (
    <PopoverContent className="shadow-[0px 24px 64px 0px rgba(0, 0, 0, 0.15)] mb-[24px] mr-[30px] rounded-2xl border-0 bg-neutral07 px-[28px] py-[32px] lg:w-[370px]">
      <div className="grid">
        <div className="space-y-2">
          <h4 className="mb-[32px] text-[24px] font-medium leading-none text-neutral01">
            Here to help 24/7
          </h4>
        </div>

        <div className="text-[16px] font-medium text-neutral03">
          Wow... Now we&apos;re even closer, right in your messenger!
        </div>

        <Button
          className="mt-[32px] w-full"
          onClick={openTelegramBot}
          disabled={isPending}
          data-test="open-telegram-button"
        >
          <TelegramIcon className="mr-[8px] h-[28px] w-[28px]" />
          Open Telegram
        </Button>
      </div>
    </PopoverContent>
  );
};

interface SupportProps {
  position?: CSSProperties['position'];
  bottom?: string;
  right?: string;
}

const Support: React.FC<SupportProps> = ({
  position = 'fixed',
  bottom = '55px',
  right = '30px',
}) => {
  const { open, setOpen } = useStore(
    useShallow((state) => ({
      open: state.isSupportModalOpen,
      setOpen: state.setIsSupportModalOpen,
    })),
  );

  const breakpoint = useBreakpoint();
  const isMobile = breakpoint.down('md');
  const isDesktop = breakpoint.up('lg');
  const { pathname } = useLocation();

  const renderPopoverContent = () => {
    if (isDesktop) {
      return <DesktopSupportPopover />;
    }

    if (!isDesktop && !isMobile) {
      return <TabletSupportPopover />;
    }

    return null;
  };

  if (pathname.startsWith(routesMap.auth)) {
    return;
  }

  return (
    <Popover open={open} onOpenChange={setOpen} modal>
      <PopoverTrigger asChild>
        <button
          className="hidden h-[60px] w-[60px] place-content-center rounded-[50px] border border-neutral06 bg-neutral08 backdrop-blur-sm md:grid"
          style={{ position, bottom, right }}
        >
          <SupportServiceIcon />
        </button>
      </PopoverTrigger>
      {open && renderPopoverContent()}
    </Popover>
  );
};

export default Support;
