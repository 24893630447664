import { useState } from 'react';

import { SelectCurrencyModal } from '@components/form/select-currency/select-currency-modal';
import { getCurrencies } from '@entities/currency';
import { ArrowDown } from '@icons/ArrowDown';
import { useBreakpoint } from '@shared/hooks/use-breakpoint';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@ui/select';

import type {
  TOnCurrencyChange,
  TSelectedCurrency,
} from '@components/form/select';

export type TSelectCurrencyProps = {
  currency?: TSelectedCurrency;
  setCurrency: TOnCurrencyChange;
  availableCurrencies?: TSelectedCurrency[] | string[];
};

export const SelectCurrency = ({
  currency,
  setCurrency,
  availableCurrencies,
}: TSelectCurrencyProps) => {
  const currencies =
    // @ts-ignore
    availableCurrencies?.length > 0 ? availableCurrencies : getCurrencies();
  const [open, setOpen] = useState(false);
  const breakpoint = useBreakpoint();

  if (breakpoint.up('md')) {
    return (
      <Select
        onOpenChange={setOpen}
        value={currency || undefined}
        open={open}
        // @ts-ignore
        onValueChange={setCurrency}
      >
        <SelectTrigger
          className="h-8 w-[4.4375rem] gap-1 rounded-lg border-0
        bg-neutral08 px-3 py-2 text-xs leading-3 text-neutral01"
        >
          <SelectValue />
        </SelectTrigger>
        <SelectContent className="hidden w-[4.4375rem]">
          {currencies?.map((currency) => (
            <SelectItem value={currency!} key={currency}>
              {currency}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    );
  }

  return (
    <div>
      <button
        className="flex h-8 w-[4.4375rem] items-center justify-between gap-1
          rounded-lg border-0 bg-neutral08 px-3 py-2 text-xs"
        onClick={() => setOpen(true)}
      >
        <span className="leading-3">{currency}</span>
        <ArrowDown className="size-3" />
      </button>
      <SelectCurrencyModal
        title="Default currency"
        selected={currency}
        onChange={setCurrency}
        open={open}
        setOpen={setOpen}
      />
    </div>
  );
};
