import MastercardIcon from '@assets/icons/mastercard.svg';
import { ClockIcon } from '@icons/ClockIcon.tsx';

const PendingDashboardCard = () => {
  return (
    <div
      className={`shadow-[0px 24px 64px 0px rgba(0, 0, 0, 0.15)] z-1 flex
        h-[9.875rem] min-h-[177px] w-[18.75rem] flex-col rounded-2xl border-[1px] border-card-stroke/20 p-6 text-[0.6875rem] font-medium tracking-widest opacity-60`}
      style={{
        background: `radial-gradient(70% 70% at 50% 50%,
    rgba(102, 108, 123, 0.50) 0%, rgba(102, 108, 123, 0.22) 50%, rgba(102, 108, 123, 0.00) 100%)`,
        boxShadow: '0px 24px 64px 0px rgba(0, 0, 0, 0.15)',
      }}
    >
      <div className="flex items-center justify-between leading-[0.5rem]">
        <img src={MastercardIcon} alt="mastercard" />
        <span className="text-[11px] uppercase tracking-[1.1px] text-neutral03">
          GCARD
        </span>
      </div>
      <div className="mt-auto flex w-full items-center justify-between">
        <div className="text-[20px] text-neutral02">Card Opening</div>
        <ClockIcon className="h-[24px] w-[24px]" />
      </div>
      <div className="mt-auto flex items-center justify-between leading-3">
        <span className="text-[11px] uppercase tracking-[1.1px] text-neutral03">
          BLACK
        </span>
      </div>
    </div>
  );
};

export default PendingDashboardCard;
