import { Link } from 'react-router-dom';

import SupportServiceIcon from '@/icons/SupportServiceIcon';
import { routesMap } from '@shared/lib';
import { Drawer, DrawerContent, DrawerTrigger } from '@ui/drawer';

import { NonDesktopSupportContent } from '../footer/support';

export const LeftSide = ({ children }: React.PropsWithChildren) => {
  return (
    <div className="flex items-center xl:gap-[24px]">
      <Link to={routesMap.home} data-test="logotype-button">
        <img
          src="/gcard-logotype.svg"
          alt="GCARD logotype"
          width={50}
          height={40}
          className="mr-6"
        />
      </Link>
      <Drawer>
        <DrawerTrigger asChild>
          <button className="grid h-8 w-8 place-content-center rounded-[50px] bg-neutral07 backdrop-blur-sm md:hidden">
            <SupportServiceIcon className="h-4 w-4 [&>path]:fill-neutral03" />
          </button>
        </DrawerTrigger>
        <DrawerContent className="z-[999999] border-0 bg-neutral07 px-[28px] pb-8 pt-4">
          <NonDesktopSupportContent />
        </DrawerContent>
      </Drawer>
      {children}
    </div>
  );
};
