import { IAccount } from './model';

export const getAccountItemName = (account?: IAccount | null) => {
  if (!account) return '';

  let type = account?.type?.toLocaleLowerCase();
  type = type && type[0].toUpperCase() + type.slice(1);
  const name = account?.currency;
  const arr = [type, name].filter((v) => v);

  return arr.join(' ');
};
