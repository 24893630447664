import { CURRENCY_NAMES } from '@entities/currency';
import { ECurrency } from './types';

export const getCurrencyName = (currency?: ECurrency | null) => {
  const emptyName = '';

  if (!currency) return emptyName;

  return CURRENCY_NAMES[currency] || emptyName;
};
