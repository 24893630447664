import { FC } from 'react';

import { IconProps } from './types';

const HomeIcon: FC<IconProps> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <defs></defs>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2095 3.44867C10.2095 3.44867 10.2095 3.44868 10.2095 3.44868L4.57269 6.98699C3.49999 7.66034 2.84479 8.86511 2.84479 10.1643V17.5116C2.84479 19.5634 4.45079 21.2268 6.43189 21.2268H17.7056C19.6867 21.2268 21.2927 19.5634 21.2927 17.5116V10.1643C21.2927 8.86511 20.6375 7.66034 19.5648 6.98699L20.0429 6.16998L19.5648 6.98699L13.9279 3.44868C13.9279 3.44867 13.9279 3.44867 13.9279 3.44867C12.7852 2.73137 11.3523 2.73137 10.2095 3.44867ZM9.2534 1.81464C10.9839 0.728454 13.1536 0.728452 14.8841 1.81464L14.8841 1.81465L20.521 5.35297C22.1454 6.37261 23.1375 8.19699 23.1375 10.1643V17.5116C23.1375 20.6186 20.7055 23.1375 17.7056 23.1375H6.43189C3.43193 23.1375 1 20.6186 1 17.5116L1 10.1643C1 8.19699 1.99215 6.37261 3.61653 5.35297L3.61653 5.35297L9.25339 1.81465L9.2534 1.81464Z"
        fill="#D1D5DB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.45703 16.6807C7.45703 16.1713 7.8995 15.7583 8.44531 15.7583H15.6927C16.2385 15.7583 16.681 16.1713 16.681 16.6807C16.681 17.1901 16.2385 17.6031 15.6927 17.6031H8.44531C7.8995 17.6031 7.45703 17.1901 7.45703 16.6807Z"
        fill="#D1D5DB"
      />
    </svg>
  );
};

export default HomeIcon;
