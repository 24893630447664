import { FormInput } from '@components/settings';
import SearchIcon from '@assets/icons/search-icon.svg?react';
import { cn } from '@lib/utils';
import React from 'react';

export interface ISearchProps extends React.ComponentProps<typeof FormInput> {
  slotProps?: {
    iconProps?: React.SVGProps<SVGSVGElement>;
  };
}

export const Search = ({
  value,
  onChange,
  className,
  slotProps,
  ...props
}: ISearchProps) => (
  <FormInput
    icon={<SearchIcon {...slotProps?.iconProps} />}
    className={cn('rounded-lg', className)}
    placeholder="Start search here"
    value={value}
    onChange={onChange}
    {...props}
  />
);
