import { ECurrency } from '@lib/currency';
import { client } from '@shared/api';
import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import type { ITransactionGroup } from '@entities/transactions';
export interface IExchangePayload {
  fromAccountId: string;
  toAccountId: string;
  fromAmount: number;
  sig: string;
}

export interface IExchangeResponse extends ITransactionGroup {}

export interface IRequestExchangeParams {
  fromAccountId: string;
  toAccountId: string;
}

export interface IFrozenRateSig {
  sig: string;
}

export interface ICurrenciesResponse {
  currencies: ECurrency[];
  rates: Record<ECurrency, number>;
}

export interface IPreComputePayload {
  fromAmount: number;
  rate: number;
  commissionPct: number;
}

export interface IConversionResult {
  result: number;
}

export interface IRequestExchangePayload {
  fromAccountId: string;
  toAccountId: string;
}

// Hooks
export const useExchange = () => {
  return useMutation<IExchangeResponse, AxiosError, IExchangePayload>({
    mutationFn: (payload) =>
      client.post('/client/exchange', payload).then((res) => res.data),
  });
};

export const useRequestExchange = () => {
  return useMutation<IFrozenRateSig, AxiosError, IRequestExchangePayload>({
    mutationFn: (payload) =>
      client.put('/client/exchange', payload).then((res) => res.data),
  });
};

export const useGetCurrencies = (
  options?: Omit<
    UseQueryOptions<ICurrenciesResponse, AxiosError>,
    'queryKey' | 'queryFn'
  >,
) => {
  return useQuery<ICurrenciesResponse, AxiosError>({
    queryKey: ['currencies'],
    queryFn: () => client.get('/currencies').then((res) => res.data),
    ...options,
  });
};

export const usePreCompute = () => {
  return useMutation<IConversionResult, AxiosError, IPreComputePayload>({
    mutationFn: (payload) =>
      client.post('/pre-compute', payload).then((res) => res.data),
  });
};
