import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { FormLabel } from '@components/settings';
import { Input, InputProps } from '@ui/input';
import { IBaseInputProps } from './types';

export interface IAmountWithDropdownProps extends IBaseInputProps {
  label?: string;
  dropdownButton?: JSX.Element | null;

  slotProps?: {
    input?: InputProps;
  };
}

export const AmountWithDropdown = forwardRef<
  HTMLInputElement,
  IAmountWithDropdownProps
>(({ value, onChange, slotProps, label, dropdownButton }, ref) => {
  const dropdownButtonRef = useRef<HTMLDivElement>(null);
  const [inputPaddingRight, setInputPaddingRight] = useState<number>();

  useEffect(() => {
    if (!dropdownButtonRef?.current) return;

    const dropdownButton = dropdownButtonRef?.current;

    const updateInputPadding = () => {
      const buttonSize = dropdownButton?.offsetWidth;
      setInputPaddingRight(buttonSize || 0);
    };

    updateInputPadding();

    const resizeObserver = new ResizeObserver(updateInputPadding);
    resizeObserver.observe(dropdownButton);

    return () => resizeObserver.disconnect();
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;

    const sanitizedInput = input.replace(/[^0-9.,]/g, '');

    let formattedInput = sanitizedInput.replace(',', '.');

    const parts = formattedInput.split('.');
    if (parts.length > 1) {
      formattedInput = `${parts[0]}.${parts[1].slice(0, 2)}`;
    }

    e.target.value = formattedInput;
    onChange && onChange(e);
  };

  return (
    <FormLabel
      label={label}
      slotProps={{ labelParagraph: { className: 'mb-[0.375rem]' } }}
    >
      <div className="relative">
        <Input
          placeholder="0"
          inputSize="xl"
          type="text"
          value={value}
          inputMode="numeric"
          onChange={handleInputChange}
          ref={ref}
          {...slotProps?.input}
          style={{
            paddingRight: inputPaddingRight,
            ...slotProps?.input?.style,
          }}
        />
        <div
          ref={dropdownButtonRef}
          className="absolute right-0 top-1/2 h-full -translate-y-1/2"
        >
          {dropdownButton}
        </div>
      </div>
    </FormLabel>
  );
});
